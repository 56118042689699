import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-bootstrap/Carousel';
import './style.css'


// Estilos customizados
const useStyles = makeStyles(() => ({
    dialogTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '1rem',
    },
    dialogContent: {
        textAlign: 'center',
        fontSize: '1.1rem',
        color: '#666',
        padding: '20px',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: '16px',
    },
    closeButton: {
        backgroundColor: '#1976d2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
}));

const PopUpDialogUnidadesCargos = ({ open, setOpen, conteudo, tipo }) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}
        >
            <DialogTitle className={classes.dialogTitle}>{tipo}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div style={{ width: 550 }}>
                    <ul>
                        {
                            conteudo?.map((row) => {
                                return <>
                                <li style={{listStyle: 'none', fontSize:'12px', textAlign:'start'}}>{row}</li>
                                <hr style={{margin: '2px', padding:'0'}} />
                                </>
                            })
                        }
                    </ul>

                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} className={classes.closeButton}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopUpDialogUnidadesCargos;
