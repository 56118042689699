import api from "../..";
import { buildQueryParams } from "../../../pages/administrativo/patrimonios/almoxarifado";

async function postMovimentacao(data) {
  return await api
    .post("patrimonio/controleestoque", data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getMovimentacoes() {
  return await api
    .get(`patrimonio/controleestoque/skip=${0}&limit=${20}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getProdutoByNome(nome) {
  return await api.get(`/administrativo/produtos`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function getAllProdutosEstoque(filters) {
  const queryString = buildQueryParams(filters);

  return await api
    .get(`administrativo/estoque${queryString}`)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
}

async function getProdutoEstoqueById(id) {
  return await api.get(`administrativo/estoque/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function postProdutoEstoque(data) {
  return await api.post("administrativo/estoque", data).then(async (res) => {
    const data = res.data;
    return data;
  });
}

async function deleteProdutoEstoqueById(id) {
  return await api.delete(`administrativo/estoque/${id}`).then(async (res) => {
    const data = res.data;
    return data;
  });
}

export {
  postMovimentacao,
  getMovimentacoes,
  getAllProdutosEstoque,
  postProdutoEstoque,
  getProdutoEstoqueById,
  deleteProdutoEstoqueById,
  getProdutoByNome,
};
