import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsServidores from '../../../pages/processos/servidores/public/urlsServidores';
import HomeServidores from '../../../pages/processos/servidores/public/homeServidores';
import ServidoresIncricoes from '../../../pages/processos/servidores/private/inscricoes';
import ServidoresIndeferir from '../../../pages/processos/servidores/private/inscricoes/indeferir';
import ServidoresAnexos from '../../../pages/processos/servidores/private/inscricoes/anexos';
import ServidoresVisualizarFormulario from '../../../pages/processos/servidores/public/visualizacao';
import FormularioProcessoSeletivoServidor from '../../../pages/processos/servidores/public/dados';
import ProcessoSeletivoCivico from '../../../pages/processos/servidores/public/dados/tab';
import ProcessoSeletivoServidoresHome from '../../../pages/processos/servidores/public';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const ServidoresRoute = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsServidores.dashboard} element={<HomeServidores />} />
      <Route path={urlsServidores.inscricoes} element={<ServidoresIncricoes />} />
      <Route path={urlsServidores.formulario} element={<ProcessoSeletivoServidoresHome />} />
      <Route path={urlsServidores.formularioDados} element={<FormularioProcessoSeletivoServidor />} />
      <Route path={urlsServidores.visualizarId} element={<ServidoresVisualizarFormulario />} />
      <Route path={urlsServidores.indeferirId} element={<ServidoresIndeferir />} />
      <Route path={urlsServidores.anexoId} element={<ServidoresAnexos />} />
    </>
    )}
  </React.Fragment>
);

export default ServidoresRoute;
