import React, { useState, useEffect } from 'react';
import {
  Grid, Card, CardContent, TextField, Menu, Button, Autocomplete, Tooltip, Chip, Box
} from '@mui/material';
import { FilterAltOutlined, TaskAlt } from '@mui/icons-material';
import { getCidades, getEstados } from '../../../../services/endereco';
import { status } from '../data/status';
import { exportarInscricoesFiltroServidores } from '../../../../services/formulariosService';
import { cargosOptions, disciplinasOptions } from '../data/options';

const FormularioServidoresInscricoesFilter = ({ filtros, setFiltros, handleSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [estadoSelecionado, setEstadoSelecionado] = useState(null);

  // Load filters from localStorage when component loads
  useEffect(() => {
    const savedFilters = JSON.parse(localStorage.getItem('servidoresFiltros')) || {};
    setFiltros(savedFilters);
  }, [setFiltros]);

  // Save filters in localStorage
  useEffect(() => {
    localStorage.setItem('servidoresFiltros', JSON.stringify(filtros));
  }, [filtros]);

  // Fetch Estados
  useEffect(() => {
    const fetchEstados = async () => {
      const response = await getEstados();
      setEstados(response.map((estado) => ({
        label: estado.descricao,
        value: estado.id,
      })));
    };
    fetchEstados();
  }, []);

  // Fetch Cidades based on Estado
  useEffect(() => {
    if (estadoSelecionado) {
      const fetchCidades = async () => {
        const response = await getCidades(estadoSelecionado.value);
        setCidades(response.map((cidade) => ({ label: cidade.nome })));
      };
      fetchCidades();
    } else {
      setCidades([]);
    }
  }, [estadoSelecionado]);

  // Update filters on change
  const handleChange = (event, value, fieldName) => {
    setFiltros({
      ...filtros,
      [fieldName]: value ?? event?.target?.value ?? '',
    });
  };

  const handleFilterSubmit = () => {
    handleSubmit();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExportar = async () => {
    await exportarInscricoesFiltroServidores(filtros);
  };

  // Map of internal field names to user-friendly labels
  const filterLabels = {
    nomeCompleto: 'Nome Completo',
    dataNascimento: 'Data de Nascimento',
    cpf: 'CPF',
    uf: 'Estado (UF)',
    cidade: 'Cidade',
    status: 'Status',
    cargoPretendido: 'Cargo Pretendido',
    disciplina: 'Disciplina',
  };

  const filtrosAplicados = Object.keys(filtros).filter((key) => filtros[key] && !['skip', 'limit', 'tipoFormulario', 'exportar', 'cargoPretendido'].includes(key));

  return (
    <>
      {/* Button Container */}
      <Box display="flex" gap={2} mb={2}>
        <Button
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          startIcon={<FilterAltOutlined />}
          variant="contained"
          size="large"
          color="info"
        >
          Filtro
        </Button>

        {/* Exportar Button */}
        <Button variant="contained" color="secondary" onClick={handleExportar} startIcon={<TaskAlt />}>
          Exportar
        </Button>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              {/* Status */}
              <Grid item xs={12}>
                <Autocomplete
                  options={status}
                  value={filtros.status || null}
                  onChange={(event, value) => handleChange(event, value, 'status')}
                  renderInput={(params) => <TextField {...params} label="Status" fullWidth />}
                />
              </Grid>

              {/* Nome Completo */}
              <Grid item xs={6}>
                <TextField label="Nome Completo" name="nomeCompleto" value={filtros.nomeCompleto || ''} onChange={(event) => handleChange(event, null, 'nomeCompleto')} fullWidth />
              </Grid>

              {/* CPF */}
              <Grid item xs={6}>
                <TextField label="CPF" name="cpf" value={filtros.cpf || ''} onChange={(event) => handleChange(event, null, 'cpf')} fullWidth inputProps={{ maxLength: 14 }} />
              </Grid>

              {/* Cargo Pretendido */}
              <Grid item xs={6}>
  <Autocomplete
    options={[...cargosOptions.map(option => ({ label: option.description, value: option.id }))]}
    value={
      filtros.cargoPretendido
        ? { label: cargosOptions.find(option => option.id === filtros.cargoPretendido)?.description }
        : { label: 'SELECIONE UM CARGO', value: 0 }
    }
    onChange={(event, newValue) => handleChange(event, newValue?.value, 'cargoPretendido')}
    getOptionLabel={(option) => option.label || ''}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    renderInput={(params) => <TextField {...params} label="Cargo Pretendido" fullWidth />}
  />
</Grid>

{filtros.cargoPretendido === 4 && (
  <Grid item xs={6}>
    <Autocomplete
      options={disciplinasOptions.map(option => ({ label: option.description, value: option.id }))}
      value={
        filtros.disciplina
          ? { label: disciplinasOptions.find(option => option.id === filtros.disciplina)?.description || '', value: filtros.disciplina }
          : null
      }
      onChange={(event, newValue) => handleChange(event, newValue?.label, 'disciplina')}
      getOptionLabel={(option) => option.label || ''}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      renderInput={(params) => <TextField {...params} label="Disciplina" fullWidth />}
    />
  </Grid>
)}


              {/* Estado */}
              <Grid item xs={6}>
                <Autocomplete
                  options={estados}
                  value={estados.find(e => e.value === filtros.uf) || null}
                  onChange={(event, newValue) => {
                    handleChange(event, newValue?.value, 'uf');
                    setEstadoSelecionado(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} label="Estado" fullWidth />}
                />
              </Grid>

              {/* Cidade */}
              <Grid item xs={6}>
                <Autocomplete
                  options={cidades}
                  value={cidades.find(c => c.label === filtros.cidade) || null}
                  onChange={(event, newValue) => handleChange(event, newValue?.label, 'cidade')}
                  renderInput={(params) => <TextField {...params} label="Cidade" fullWidth />}
                />
              </Grid>

              {/* Aplicar Filtros */}
              <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleFilterSubmit} fullWidth>
                  Aplicar Filtros
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Menu>

      {/* Render applied filters as tooltips */}
      <Box mt={4}>
        <Grid container spacing={1}>
          {filtrosAplicados.map((filtroKey, index) => (
            <Grid item key={index}>
              <Tooltip title={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} arrow>
                <Chip label={`${filterLabels[filtroKey]}: ${filtros[filtroKey]}`} sx={{ backgroundColor: '#1976d2', color: '#fff' }} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default FormularioServidoresInscricoesFilter;
