import { useMemo, useEffect, useState } from "react";
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseConfiguracoes from "../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../configuracoes/urlsConfiguracoes";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "react-query";
import { getDistritos } from "../../services/unidades";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/spinner";

const DistritosPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [skip, setSkip] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(20);
  const [ativo, setAtivo] = useState(true);
  const [descricao, setDescricao] = useState("");
  const [warningControl, setWarningControl] = useState(false);

  const { isLoading: loading, refetch } = useQuery(
    "getDistritos",
    () => getDistritos(skip, limit),
    {
      onSuccess: (data) => {
        setData(data?.data);
        setTotal(data?.total);
        setLimit(data?.limit);
        setSkip(data?.skip);
      },
      enabled: true,
      retry: false,
    }
  );

  useEffect(() => {
    refetch();
  }, [limit, skip]);

  const columns = useMemo(
    () => [
      { Header: "#", accessor: "id" },
      { Header: "Descrição", accessor: "descricao" },
      { Header: "Cidade", accessor: "cidade" },
      { Header: "Estado", accessor: "estado" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                navigate(urlsConfiguracoes.distritosEditar + row.original.id, {
                  state: {
                    id: row.original.id,
                    distrito: {
                      descricao: row.original.descricao,
                      cidade: row.original.cidade,
                      estado: row.original.estado,
                    },
                  },
                })
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              onClick={() =>
                navigate(urlsConfiguracoes.distritoAlocar + row.original.id, {
                  state: {
                    id: row.original.id,
                    distrito: {
                      descricao: row.original.descricao,
                    },
                  },
                })
              }
              className="btn btn-sm btn-success"
            >
              <i className="material-icons md-edit_note"></i> alocar
            </button>
          </>
        ),
      },
    ],
    [navigate, data]
  );

  return (
    <BaseConfiguracoes>
      <Breadcrumb
        title={"Distritos"}
        itens={["Configurações", "Parâmetros", "Distritos", "Lista Geral"]}
        actions={{
          link: urlsConfiguracoes.distritosAdicionar,
          text: "Novo Distrito",
        }}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            {loading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={data === undefined ? [] : data}
                hasPagination
                limit={limit}
                setLimit={setLimit}
                skip={skip}
                setSkip={setSkip}
                totalItems={total}
                hiddenColluns={["id"]}
              />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  );
};

export default DistritosPage;
