import { useEffect, useMemo, useState } from "react";
import Breadcrumb from "../../../../components/breadcrumb";
import Table from "../../../../components/table";
import BaseAdministrativo from "../../baseAdministrativo";
import urlsAdministrativo from "../../urlsAdministrativo";
import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteProdutoEstoqueById,
  getAllProdutosEstoque,
  getProdutoByNome,
} from "../../../../services/administrativo/patrimonio-estoque";
import { conservacao, tipoProduto } from "./cadastrar/tab/produtos";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FilterAltOutlined } from "@mui/icons-material";

export function buildQueryParams(filters) {
  const {
    skip,
    limit,
    searchText,
    produtoId,
    marca,
    tipoProduto,
    conservacao,
    validadeInicio,
    validadeFim,
  } = filters;

  const queryParams = {};

  if (skip !== undefined && skip !== null) queryParams.skip = skip;
  if (limit !== undefined && limit !== null) queryParams.limit = limit;
  if (searchText) queryParams.searchText = searchText;
  if (produtoId) queryParams.produtoId = produtoId;
  if (marca) queryParams.marca = marca;
  if (tipoProduto) queryParams.tipoProduto = tipoProduto;
  if (conservacao) queryParams.conservacao = conservacao;
  if (validadeInicio) queryParams.validadeInicio = validadeInicio;
  if (validadeFim) queryParams.validadeFim = validadeFim;

  const queryString = new URLSearchParams(queryParams).toString();
  return queryString ? `?${queryString}` : "";
}

const AlmoxarifadoEstoque = () => {
  const navigate = useNavigate();
  const [produtos, setProdutos] = useState([]);
  const [newData, setNewData] = useState();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [searchFilter, setSearchFilter] = useState("");
  const [produtoIdFilter, setProdutoIdFilter] = useState(0);
  const [marcaFilter, setMarcaFilter] = useState("");
  const [tipoProdutoFilter, setTipoProdutoFilter] = useState(0);
  const [conservacaoFilter, setConservacaoFilter] = useState(0);
  const [validadeInicioFilter, setValidadeInicioFilter] = useState(null);
  const [validadeFimFilter, setValidadeFimFilter] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const onMenuOpen = () => setIsOpen(true);
  const onMenuClose = () => setIsOpen(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseB = () => {
    setAnchorEl(null);
  };

  const { data: estoqueData, refetch: refetchEstoque } = useQuery({
    queryKey: "getProdutosEstoque",
    queryFn: () =>
      getAllProdutosEstoque({
        skip,
        limit,
        searchText: searchFilter,
        produtoId: produtoIdFilter,
        marca: marcaFilter,
        tipoProduto: tipoProdutoFilter,
        conservacao: conservacaoFilter,
        validadeInicio: validadeInicioFilter,
        validadeFim: validadeFimFilter,
      }),
    enabled: true,
    onSuccess: (data) => {
      setNewData(data?.data);
    },
  });

  const { data } = useQuery("getProdutos", () => getProdutoByNome(), {
    enabled: true,
    onSuccess: (data) => {
      setProdutos(
        data?.data.map((produto) => ({
          label: produto.nome,
          id: produto.id,
        }))
      );
    },
  });

  const { mutate } = useMutation((id) => deleteProdutoEstoqueById(id), {
    onSuccess: (data) => {
      toast.success("Produto excluído com sucesso.");
      refetchEstoque();
    },
    onError: (data) => {
      toast.error("Erro ao excluir produto. Tente novamente mais tarde.");
    },
  });

  let BRLFormat = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const formatValue = (value) => {
    if (value) {
      const newValue = `${BRLFormat.format(value)}`;
      return newValue;
    }
  };

  const getTipoProdutoLabel = (value) => {
    const tipo = tipoProduto.find((item) => item.value === value);
    return tipo ? tipo.label : "-";
  };

  const getConservacaoLabel = (value) => {
    const conservacaoItem = conservacao.find((item) => item.value === value);
    return conservacaoItem ? conservacaoItem.label : "-";
  };

  const formatDate = (date) => {
    const offsetDate = new Date(date);
    offsetDate.setMinutes(
      offsetDate.getMinutes() + offsetDate.getTimezoneOffset()
    );
    return offsetDate.toLocaleDateString("pt-BR");
  };

  useEffect(() => {
    refetchEstoque();
  }, [skip, limit]);

  const columns = useMemo(
    () => [
      { Header: "Produto", accessor: "nomeProduto" },
      {
        Header: "Marca",
        accessor: "marca",
      },
      {
        Header: "Tipo",
        accessor: "tipoProduto",
        Cell: ({ value }) => getTipoProdutoLabel(value),
      },

      {
        Header: "Validade",
        accessor: "dataValidade",
        Cell: ({ value }) =>
          value ? new Date(value).toLocaleDateString("pt-BR") : "-",
      },
      {
        Header: "Conservação",
        accessor: "tipoConservacao",
        Cell: ({ value }) => getConservacaoLabel(value),
      },
      {
        Header: "Valor",
        accessor: "valor",
        Cell: ({ row }) => formatValue(row.original.valor),
      },
      { Header: "Quantidade", accessor: "quantidade" },
      {
        Header: "",
        accessor: "actions",
        Cell: ({ row }) => (
          <>
            <button
              onClick={() =>
                navigate(
                  urlsAdministrativo.patrimonioAlmoxarifadoEstoqueCadastrar +
                    row.original.id,
                  {
                    state: {
                      data: row.original,
                    },
                  }
                )
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> visualizar
            </button>
            <button
              className="btn btn-sm btn-danger"
              style={{ marginLeft: "8px" }}
              onClick={() => {
                const confirm = window.confirm(
                  "Tem certeza que deseja excluir?"
                );
                if (confirm) {
                  mutate(row.original.id);
                }
              }}
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        ),
      },
    ],
    [newData]
  );

  return (
    <BaseAdministrativo>
      <Breadcrumb
        title={"Estoque"}
        itens={["Administrativo", "Patrimônio", "Estoque"]}
        actions={{
          link: urlsAdministrativo.patrimonioAlmoxarifadoEstoqueCadastrar,
          text: "Novo Produto",
        }}
      />
      <div className="card">
        <div
          style={{
            margin: "10px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            startIcon={<FilterAltOutlined />}
            variant="contained"
            size="large"
            color="info"
          >
            Filtro
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseB}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <div style={{ padding: "20px", width: "400px" }}>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="NOME"
                    value={searchFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setSearchFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    label="MARCA"
                    value={marcaFilter}
                    variant="outlined"
                    onChange={async (e) => {
                      await setMarcaFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="tipoProdutoFilter">TIPO</InputLabel>
                  <Select
                    labelId="tipoProdutoFilter"
                    id="tipoProdutoFilter"
                    value={tipoProdutoFilter}
                    label="Tipo"
                    onChange={async (e) => {
                      await setTipoProdutoFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {tipoProduto.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <InputLabel id="conservacao">CONSERVAÇÃO</InputLabel>
                  <Select
                    labelId="conservacao"
                    id="conservacao"
                    value={conservacaoFilter}
                    label="Conservação"
                    onChange={async (e) => {
                      await setConservacaoFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  >
                    <MenuItem value={null}></MenuItem>
                    {conservacao.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <Autocomplete
                    disablePortal
                    options={produtos}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="PRODUTO" />
                    )}
                    onChange={async (_, newValue) => {
                      if (newValue) {
                        await setProdutoIdFilter(newValue.id);
                        await refetchEstoque();
                      }
                    }}
                  />
                </FormControl>
              </div>

              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA VALIDADE INÍCIO"
                    value={validadeInicioFilter}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setValidadeInicioFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div className="mt-4">
                <FormControl fullWidth>
                  <TextField
                    id="outlined-basic"
                    type="date"
                    label="DATA VALIDADE FIM"
                    value={validadeFimFilter}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={async (e) => {
                      await setValidadeFimFilter(e.target.value);
                      await refetchEstoque();
                    }}
                  />
                </FormControl>
              </div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Chip
                  sx={{ marginTop: 2 }}
                  color="error"
                  label="Limpar filtros"
                  onClick={async () => {
                    await setSearchFilter("");
                    await setTipoProdutoFilter(0);
                    await setProdutoIdFilter(0);
                    await setMarcaFilter("");
                    await setConservacaoFilter(0);
                    await setValidadeInicioFilter(null);
                    await setValidadeFimFilter(null);
                    await refetchEstoque();
                  }}
                />
              </div>
            </div>
          </Menu>

          {tipoProdutoFilter > 0 ? (
            <Chip
              color="info"
              label={`TIPO: ${
                tipoProduto.find((item) => item.value === tipoProdutoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setTipoProdutoFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {conservacaoFilter > 0 ? (
            <Chip
              color="info"
              label={`CONSERVAÇÃO: ${
                conservacao.find((item) => item.value === conservacaoFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setConservacaoFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {marcaFilter != "" ? (
            <Chip
              color="info"
              label={`MARCA: ${marcaFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setMarcaFilter("");
                await refetchEstoque();
              }}
            />
          ) : null}
          {searchFilter != "" ? (
            <Chip
              color="info"
              label={`NOME: ${searchFilter.toLocaleUpperCase()}`}
              variant="outlined"
              onDelete={async () => {
                await setSearchFilter("");
                await refetchEstoque();
              }}
            />
          ) : null}
          {produtoIdFilter > 0 ? (
            <Chip
              color="info"
              label={`PRODUTO: ${
                produtos.find((produto) => produto.id === produtoIdFilter)
                  ?.label || "-"
              }`}
              variant="outlined"
              onDelete={async () => {
                await setProdutoIdFilter(0);
                await refetchEstoque();
              }}
            />
          ) : null}
          {validadeInicioFilter && validadeFimFilter != null ? (
            <Chip
              color="info"
              label={`VALIDADE: ${formatDate(
                validadeInicioFilter
              )} - ${formatDate(validadeFimFilter)}`}
              variant="outlined"
              onDelete={async () => {
                await setValidadeInicioFilter(null);
                await setValidadeFimFilter(null);
                await refetchEstoque();
              }}
            />
          ) : null}
        </div>
        <Table
          hasPagination
          skip={skip}
          setSkip={setSkip}
          limit={limit}
          setLimit={setLimit}
          totalItems={estoqueData?.total}
          columns={columns}
          data={estoqueData?.data ? estoqueData?.data : []}
        />
      </div>
    </BaseAdministrativo>
  );
};
export default AlmoxarifadoEstoque;
