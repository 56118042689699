import { useMemo, useEffect, useState } from 'react';
import Breadcrumb from "../../components/breadcrumb";
import Table from '../../components/table';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { getNacionalidades } from '../../services/servidores';
import { relatorioNacionalidades } from '../../services/nacionalidade';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner';

const NacionalidadesPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [warningControl, setWarningControl] = useState(false);

  const { isLoading, refetch } = useQuery('getNacionalidades', () => getNacionalidades(), {
    onSuccess: (response) => {
      setData(response.data);
      setFilteredData(response.data); // Initialize filtered data
    },
    enabled: true,
    retry: true,
  });

  useEffect(() => {
    // Filter data locally when 'descricao' changes
    const filtered = data.filter((item) =>
      item.descricao.toLowerCase().includes(descricao.toLowerCase())
    );
    setFilteredData(filtered);
  }, [descricao, data]);

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button
              onClick={() => navigate(urlsConfiguracoes.nacionalidadeEditar + row.original.id, {
                state: {
                  id: row.original.id,
                  nacionalidade: {
                    descricao: row.original.descricao,
                  },
                },
              })}
              className='btn btn-sm btn-warning'
            >
              <i className="material-icons md-edit_note"></i> editar
            </button>
          </>
        ),
      },
    ],
    [navigate, data]
  );

  const handleDownloadExcel = async () => {
    try {
      const response = await relatorioNacionalidades();
      const fileName = `nacionalidades.xlsx`;
      const url = window.URL.createObjectURL(
        new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          encoding: 'UTF-8',
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("Erro ao gerar o arquivo Excel.");
    }
  };

  return (
    <BaseConfiguracoes>
      <Breadcrumb
        title={'Nacionalidades'}
        itens={['Gestão', 'Parâmetros', 'Nacionalidades', 'Lista Geral']}
        hasExcelButton
        onClickDownload={() => handleDownloadExcel()}
        actions={{ link: urlsConfiguracoes.nacionalidadeAdicionar, text: "Nova Nacionalidade" }}
      />
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          marginTop: 10,
          marginBottom: 20,
        }}
      >
        {warningControl && descricao.length < 3 && (
          <span style={{ marginRight: '10px', alignSelf: 'center', color: '#CC7700' }}>
            Digite ao menos 3 caracteres para realizar a busca!
          </span>
        )}
        <div className="form-group" style={{ width: '100%', maxWidth: 300 }}>
          <input
            type="text"
            className="form-control"
            placeholder="Pesquisar por descrição"
            value={descricao}
            onChange={(e) => setDescricao(e.target.value)}
            onMouseEnter={() => setWarningControl(true)}
            onMouseOut={() => setWarningControl(false)}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {isLoading ? (
              <Spinner />
            ) : (
              <Table
                columns={columns}
                data={filteredData}
                hasPagination={false}
              />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  );
};

export default NacionalidadesPage;
