import React from "react";
import { Container, Typography, Grid, Card, CardContent, Box, CircularProgress } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb";
import BasePreMatricula from "./basePreMatricula";
import { useQuery } from "react-query";
import { getDashboardPreMatricula } from "../../../services/dashboard";
import ChildCareIcon from '@mui/icons-material/ChildCare';
import SchoolIcon from '@mui/icons-material/School';
import Filter1Icon from '@mui/icons-material/Filter1';
import Filter2Icon from '@mui/icons-material/Filter2';
import Filter3Icon from '@mui/icons-material/Filter3';
import Filter4Icon from '@mui/icons-material/Filter4';
import Filter5Icon from '@mui/icons-material/Filter5';
import Filter6Icon from '@mui/icons-material/Filter6';
import Filter7Icon from '@mui/icons-material/Filter7';
import Filter8Icon from '@mui/icons-material/Filter8';
import Filter9Icon from '@mui/icons-material/Filter9';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import { Bar, Pie, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement } from "chart.js";

// Register chart.js components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);


const StatCard = ({ title, value, icon, bgColor }) => (
  <Card sx={{ backgroundColor: bgColor, borderLeft: "5px solid #3f51b5", mb: 2 }}>
    <CardContent>
      <Box display="flex" alignItems="center">
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: 60, height: 60 }}>
          {icon}
        </Box>
        <Box ml={2}>
          <Typography variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="h5">{value || "-"}</Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const SummaryCard = ({ title, value }) => (
  <Card
    sx={{
      backgroundColor: "#f5f5f5",
      borderRadius: 2,
      boxShadow: 1,
      height: "100%",
    }}
  >
    <CardContent>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 60,
            height: 60,
            borderRadius: "50%",
            backgroundColor: "#e3f2fd",
            mr: 2,
          }}
        >
          <GroupsIcon sx={{ fontSize: 40, color: "#3f51b5" }} />
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ color: "#616161", fontWeight: "bold" }}
          >
            {title.toUpperCase()}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {value || "0"}
          </Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const renderSection = (title, stats, bgColor) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" sx={{ mb: 2, fontWeight: "bold" }}>
      {title}
    </Typography>
    <Grid container spacing={2}>
      {stats.map((stat, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <StatCard {...stat} bgColor={bgColor} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

const HomePreMatricula = () => {
  const { data, isLoading } = useQuery("getDashboardPreMatricula", getDashboardPreMatricula, { retry: 3 });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const summaryStats = [
    { title: "Total Inscritos", value: data?.inscricoesListaEsperaTotal, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Inscritos Por CPF", value: data?.inscricoesListaEsperaTotal, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Inscritos Pré-Matrícula", value: data?.inscricoesListaEspera, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Inscritos Pré-Matrícula (Rede)", value: data?.inscricoesListaEsperaTotalRede, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Inscritos Pré-Matrícula (Fora da Rede)", value: data?.inscricoesListaEsperaTotalForaRede, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Convocados", value: data?.totalConvocados, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
    { title: "Total Matriculados", value: data?.totalMatriculados, icon: <GroupsIcon style={{ color: "#3f51b5", fontSize: 40 }} /> },
  ];

  const sections = [
    {
      title: "Educação Infantil - Creche e Pré",
      stats: [
        { title: "Creche I", value: data?.inscricoesValidas12, icon: <ChildCareIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Creche II", value: data?.inscricoesValidas13, icon: <ChildCareIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Pré I", value: data?.inscricoesValidas24, icon: <SchoolIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Pré II", value: data?.inscricoesValidas25, icon: <SchoolIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
      ],
      bgColor: "#e3f2fd", // Light blue
    },
    {
      title: "Ensino Fundamental I (1º ao 5º Ano)",
      stats: [
        { title: "1º Ano", value: data?.inscricoesValidas1, icon: <Filter1Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "2º Ano", value: data?.inscricoesValidas2, icon: <Filter2Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "3º Ano", value: data?.inscricoesValidas3, icon: <Filter3Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "4º Ano", value: data?.inscricoesValidas4, icon: <Filter4Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "5º Ano", value: data?.inscricoesValidas5, icon: <Filter5Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
      ],
      bgColor: "#fbe9e7", // Light orange
    },
    {
      title: "Ensino Fundamental II (6º ao 9º Ano)",
      stats: [
        { title: "6º Ano", value: data?.inscricoesValidas6, icon: <Filter6Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "7º Ano", value: data?.inscricoesValidas8, icon: <Filter7Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "8º Ano", value: data?.inscricoesValidas10, icon: <Filter7Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "9º Ano", value: data?.inscricoesValidas11, icon: <Filter7Icon style={{ color: "#3f51b5", fontSize: 50 }} /> },
      ],
      bgColor: "#e8f5e9", // Light green
    },
    {
      title: "Educação de Jovens e Adultos - EJA",
      stats: [
        { title: "Fase I", value: data?.inscricoesValidas16, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase II", value: data?.inscricoesValidas17, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase III", value: data?.inscricoesValidas18, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase IV", value: data?.inscricoesValidas19, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase V", value: data?.inscricoesValidas20, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase VI", value: data?.inscricoesValidas21, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase VII", value: data?.inscricoesValidas22, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
        { title: "Fase VIII", value: data?.inscricoesValidas23, icon: <PersonIcon style={{ color: "#3f51b5", fontSize: 50 }} /> },
      ],
      bgColor: "#ede7f6", // Light purple
    },
  ];

  const turnoData = {
    labels: data.turnoDistribuicao.map(item => item.turnoDescricao),
    datasets: [
      {
        label: "Distribuição por Turno",
        data: data.turnoDistribuicao.map(item => item.total),
        backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#FF7043"],
      },
    ],
  };

  const idadeData = {
    labels: data.idadeDistribuicao.map(item => item.idade),
    datasets: [
      {
        label: "Distribuição por Idade",
        data: data.idadeDistribuicao.map(item => item.total),
        backgroundColor: "#42A5F5",
      },
    ],
  };

  const bairroData = {
    labels: data.bairroDistribuicao.slice(0, 10).map(item => item.bairro),
    datasets: [
      {
        label: "Distribuição por Bairro (Top 10)",
        data: data.bairroDistribuicao.slice(0, 10).map(item => item.total),
        backgroundColor: "#66BB6A",
      },
    ],
  };

  const statusColors = {
    "PRÉ-MATRÍCULA": "#42A5F5", // Blue
    "CONVOCADO": "#66BB6A", // Green
    "MATRICULADO": "#FF7043", // Orange
    "NÃO COMPARECIDO": "#FFC107", // Yellow
    "INDEFERIDO": "#D32F2F", // Red
    "CANCELADO": "#9E9E9E", // Gray
    "DESISTÊNCIA": "#AB47BC", // Purple
    "MATRICULADO POR OUTRA OPÇÃO": "#8D6E63", // Brown
    "CONVOCADO POR OUTRA OPÇÃO": "#26C6DA", // Cyan
    "MATRICULADO EM OUTRA OPÇÃO": "#EF5350", // Light Red
    "SEM STATUS": "#BDBDBD", // Light Gray
  };

  const statusData = {
    labels: data.statusDistribuicao.map(item => item.statusNome),
    datasets: [
      {
        label: "Distribuição por Status",
        data: data.statusDistribuicao.map(item => item.total),
        backgroundColor: data.statusDistribuicao.map(item => statusColors[item.statusNome] || "#BDBDBD"), // Default to Light Gray if no color is found
        // borderColor: "#000000", // Add a border for a sharper look
        // borderWidth: 1, // Define border width
      },
    ],
  };

  const deficienteData = {
    labels: ["Deficientes", "Não Deficientes"],
    datasets: [
      {
        label: "Distribuição por Deficiência",
        data: [data.deficienteDistribuicao.totalDeficientes, data.deficienteDistribuicao.totalNaoDeficientes],
        backgroundColor: ["#AB47BC", "#66BB6A"],
      },
    ],
  };

  const topUnidadesData = {
    labels: data.top5Unidades.map(item => item.nome),
    datasets: [
      {
        label: "Top 5 Unidades",
        data: data.top5Unidades.map(item => item.totalEscolhas),
        backgroundColor: "#FFA726",
      },
    ],
  };

  return (
    <BasePreMatricula>
      <Breadcrumb title="Dados Gerais das Inscrições" itens={["Pré-Matricula 2025", "Dashboard"]} />
      <Container maxWidth="lg" sx={{ py: 4 }}>
        {/* Top Summary Section */}
        <Grid container spacing={2}>
  {/* Row 1 */}
  <Grid item xs={12} sm={6} md={6}>
    <StatCard
      title="Total Inscritos"
      value={data?.inscricoesTotal}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>
  <Grid item xs={12} sm={6} md={6}>
    <StatCard
      title="Total Inscritos por CPF"
      value={data?.inscricoesValidas}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>

  {/* Row 2 */}
  <Grid item xs={12} sm={6} md={4}>
    <StatCard
      title="Total Inscritos Pré-Matrícula"
      value={data?.inscricoesListaEsperaTotal}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>
  <Grid item xs={12} sm={6} md={4}>
    <StatCard
      title="Total Inscritos Pré-Matrícula (Rede)"
      value={data?.inscricoesListaEsperaTotalRede}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>
  <Grid item xs={12} sm={6} md={4}>
    <StatCard
      title="Total Inscritos Pré-Matrícula (Ñ Rede)"
      value={data?.inscricoesListaEsperaTotalForaRede}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>

  {/* Row 3 */}
  <Grid item xs={12} sm={6} md={6}>
    <StatCard
      title="Total Convocado"
      value={data?.totalConvocados}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>
  <Grid item xs={12} sm={6} md={6}>
    <StatCard
      title="Total Matriculados"
      value={data?.totalMatriculados}
      icon={<GroupsIcon style={{ color: "#3f51b5", fontSize: 50 }} />}
    />
  </Grid>
</Grid>

       


         
        {sections.map((section, index) => renderSection(section.title, section.stats, section.bgColor))}

        <Grid container spacing={4}>
        {/* Turno Distribuição */}
        <Grid item xs={12} md={6}>
          <Bar data={turnoData} options={{ responsive: true, plugins: { legend: { display: true } } }} />
        </Grid>

        {/* Idade Distribuição */}
        <Grid item xs={12} md={6}>
          <Bar data={idadeData} options={{ responsive: true, plugins: { legend: { display: true } } }} />
        </Grid>

        {/* Bairro Distribuição */}
        <Grid item xs={12} md={6}>
          <Bar data={bairroData} options={{ responsive: true, plugins: { legend: { display: true } } }} />
        </Grid>

        {/* Status Distribuição */}
        <Grid item xs={12} md={6}>
  <Bar
    data={statusData}
    options={{
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
      },
      indexAxis: "y", // Makes the bar chart horizontal
      scales: {
        x: {
          beginAtZero: true,
          
        },
        y: {
          grid: {
            display: false, // Remove grid lines for y-axis
          },
        },
      },
    }}
  />
</Grid>

        {/* Deficiência Distribuição */}
<Grid item xs={12} md={6}>
  <Bar
    data={{
      labels: ["Deficientes", "Não Deficientes"],
      datasets: [
        {
          label: "Distribuição por Deficiência",
          data: [data.deficienteDistribuicao.totalDeficientes, data.deficienteDistribuicao.totalNaoDeficientes],
          backgroundColor: ["#AB47BC", "#66BB6A"], // Colors for each category
        },
      ],
    }}
    options={{
      indexAxis: "y", // Makes the bar chart horizontal
      responsive: true,
      plugins: {
        legend: {
          display: true, // Display the legend
          position: "top",
        },
      },
      scales: {
        x: {
          beginAtZero: true, // Ensure x-axis starts at zero
        },
      },
    }}
  />
</Grid>

        {/* Top 5 Unidades */}
        <Grid item xs={12} md={6}>
          <Bar data={topUnidadesData} options={{ responsive: true, plugins: { legend: { display: true } } }} />
        </Grid>
        </Grid>
      </Container>
    </BasePreMatricula>
  );
};

export default HomePreMatricula;
