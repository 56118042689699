import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-bootstrap/Carousel';
import './style.css'


// Estilos customizados
const useStyles = makeStyles(() => ({
    dialogTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '1rem',
    },
    dialogContent: {
        textAlign: 'center',
        fontSize: '1.1rem',
        color: '#666',
        padding: '20px',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: '16px',
    },
    closeButton: {
        backgroundColor: '#1976d2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
}));

const PopUpDialogPreview = ({ open, setOpen, conteudo, tipo }) => {
    const imageUrl = `${process.env.PUBLIC_URL}/assets/images/fundo/fundo-branco.png`;
    const popUps = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).user.listaAvisos;

    const classes = useStyles();
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}
        >
            <DialogTitle className={classes.dialogTitle}>Preview Conteúdo</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div style={{ width: 500 }}>
                    {
                        tipo == 1 ? <p>{conteudo}</p>
                            : tipo == 2 ? <img height="400px" src={"data:image/jpeg;base64," + conteudo} alt="Slide" />
                            : <video
                            src={"data:video/mp4;base64," + conteudo}
                            controls
                            width="350"
                            style={{ display: "block", marginTop: "10px" }}></video>
                }

                </div>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} className={classes.closeButton}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopUpDialogPreview;
