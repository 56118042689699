import { useMemo } from 'react';
import Breadcrumb from "../../components/breadcrumb";
import Table from "../../components/table";
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { toast } from 'react-toastify';
import { useQuery, useMutation } from 'react-query';
import { getTurnos, deleteTurno } from '../../services/turnos';
import { useNavigate } from "react-router-dom";
import Spinner from '../../components/spinner';

const TurnosPage = () => {
  const navigate = useNavigate();

  const { data, isLoading: loading, refetch } = useQuery('getTurnos', getTurnos, {
    retry: 3
  });

  const { mutate } = useMutation(deleteTurno, {
    onSuccess: (message) => {
      toast.success(message);
      refetch();
    },
    onError: (error) => {
      if (error.response) {
        const { message } = error.response.data;
        toast.error('Turno desabilitado com sucesso');
      } else if (error.request) {
        toast.error('Erro ao tentar excluir, tente novamente mais tarde.');
      } else {
        toast.error(error.message);
      }
    }
  });

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Turno', accessor: 'descricao' },
      { Header: 'Sigla', accessor: 'sigla' },
      {
        Header: 'Status', accessor: 'status',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.status ? 'success' : 'danger'}`}>
            {row.original.status ? 'Ativo' : 'Inativo'}
          </span>
        )
      },
      {
        Header: '', accessor: 'actions',
        Cell: ({ row }) => (
          <>
            <button onClick={() => navigate(urlsConfiguracoes.turnoEditar + row.original.id, {
              state: {
                id: row.original.id,
                turno: {
                  descricao: row.original.descricao,
                  sigla: row.original.sigla,
                  status: row.original.status
                }
              }
            })} className='btn btn-sm btn-warning'>
              <i className="material-icons md-edit_note"></i> editar
            </button>
            {' '}
            <button className='btn btn-sm btn-danger' onClick={() => {
              if (window.confirm('Deseja realmente excluir?')) {
                mutate(row.original.id);
              }
            }}>
              <i className="material-icons md-delete"></i> inativar
            </button>
          </>
        )
      },
    ],
    [mutate, navigate]
  )

  return (
    <BaseConfiguracoes>
      <Breadcrumb title={'Turnos'} itens={['Gestão', 'Secretaria', 'Turnos', 'Lista Geral']} actions={{ link: urlsConfiguracoes.turnoAdicionar, text: "Novo Turno" }} />
      <div className='row'>
        <div className='col-12'>
          <div className='card'>
            {loading ? (
              <Spinner />
            ) : (
              <Table columns={columns} data={data ? data : []} hasPagination pageSize={data.limit} />
            )}
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )

};

export default TurnosPage;
