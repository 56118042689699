import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  cincoCursos,
  doisCursos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
} from "../data/options";

export function MotoristaClasseDForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Cursos para Condutores de Veículos de Transporte Escolar{" "}
          <strong>na área de atuação do cargo de MOTORISTA CLASSE D</strong> de
          no mínimo 50h (cinquenta horas) - por certificado - concluídos no
          período entre 2019 e a data inicial de inscrição deste processo
          seletivo simplificado
        </Typography>
        <Controller
          name="cursoCondutoresVeiculos"
          control={control}
          rules={{ required: "Curso para Condutores é obrigatório." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  doisCursos?.find(
                    (c) => c.id == values?.cursoCondutoresVeiculos
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={doisCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoCondutoresVeiculos}
                    helperText={
                      errors.cursoCondutoresVeiculos
                        ? errors.cursoCondutoresVeiculos.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Capacitação de Primeiros Socorros – com carga horária mínima de 04
          (quatro) horas - concluída no período entre 2019 e a data inicial de
          inscrição deste processo seletivo simplificado
        </Typography>
        <Controller
          name="capacitacaoPrimeirosSocorros"
          control={control}
          rules={{
            required: "Capacitação em Primeiros Socorros é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.capacitacaoPrimeirosSocorros}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <RadioGroup
                row
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
            )
          }
        />
        {errors.capacitacaoPrimeirosSocorros && (
          <Typography color="error">
            {errors.capacitacaoPrimeirosSocorros.message}
          </Typography>
        )}
      </Grid>
    </>
  );
}
