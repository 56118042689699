import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import FormInput from "../../../../../../../components/formInput";

export default function Categoria({
  register,
  errors,
  setValue,
  canEdit,
  watch,
}) {
  const { id } = useParams();
  const { state } = useLocation();

  useEffect(() => {
    if (id) {
      setValue("id", id);
      setValue("descricao", state.descricao);
      setValue("status", state.status);
    }
  }, [state, setValue]);

  return (
    <div
      className="tab-pane fade show active"
      id="categoria"
      role="tabpanel"
      aria-labelledby="categoria-tab"
    >
      <div className="card-body">
        <div className="row">
          {id && (
            <div className="col-md-1">
              <FormInput
                register={register}
                errors={errors}
                atribute="id"
                label="ID"
                readOnly={true}
              />
            </div>
          )}

          <div className={id ? "col-md-6" : "col-md-7"}>
            <FormInput
              register={register}
              errors={errors}
              atribute="descricao"
              label="Descrição"
            />
          </div>

          <div className="col-md-5">
            <label htmlFor="status">
              Status <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            </label>
            <select
              className="form-select"
              id="status"
              {...register("status", { required: true })}
            >
              <option value={null}>SELECIONE</option>
              <option key="1" value={true}>
                ATIVO
              </option>
              <option key="2" value={false}>
                INATIVO
              </option>
            </select>
            {errors.status?.type === "required" && (
              <div role="alert" className="invalid-feedback d-block">
                Campo obrigatório!
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
