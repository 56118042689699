export const escolaridadeArray =
    process.env.REACT_APP_SECRETARIA_MUNICIPAL === "SEDUC"
      ? [
          "CRECHE I (6 MESES A 11 MESES)",
          "CRECHE II (1 ANO A 1 ANO E 11 MESES)",
          "CRECHE III (2 ANOS A 2 ANOS E 11 MESES)",
          "CRECHE IV (3 ANOS A 3 ANOS E 11 MESES)",
          "PRÉ I (4 ANOS A 4 ANOS E 11 MESES)",
          "PRÉ II (5 ANOS A 5 ANOS E 11 MESES)",
          "1º ANO",
          "2º ANO",
          "3º ANO",
          "4º ANO",
          "5º ANO",
          "6º ANO",
          "7º ANO",
          "8º ANO",
          "9º ANO",
          "FASE II",
          "FASE III",
          "FASE IV",
          "FASE IX",
          "FASE V",
          "FASE VI",
          "FASE VII",
          "FASE VIII",
        ]
      : [
          "CRECHE I",
          "CRECHE II",
          "CRECHE III",
          "CRECHE IV",
          "PRÉ I",
          "PRÉ II",
          "1º ANO",
          "2º ANO",
          "3º ANO",
          "4º ANO",
          "5º ANO",
          "6º ANO",
          "7º ANO",
          "8º ANO",
          "9º ANO",
          "FASE I",
          "FASE II",
          "FASE III",
          "FASE IV",
          "FASE IX",
          "FASE V",
          "FASE VI",
          "FASE VII",
          "FASE VIII",
        ];