import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { putProduto } from "../../../../../../../services/administrativo/patrimonio-produto";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import upperCaseSimple from "../../../../../../../utils/upperCaseSimple";
import removeEmpty from "../../../../../../../utils/removeEmpty";
import {
  getProdutoByNome,
  getProdutoEstoqueById,
  postProdutoEstoque,
} from "../../../../../../../services/administrativo/patrimonio-estoque";
import { getGrupoProdutos } from "../../../../../../../services/administrativo/patrimonio";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Autocomplete,
  Button,
  InputAdornment,
  OutlinedInput,
  Alert,
} from "@mui/material";
import { store } from "../../../../../../../store";

export const tipoProduto = [
  { value: 1, label: "DURÁVEL" },
  { value: 2, label: "NÃO DURÁVEL" },
];

export const conservacao = [
  { value: 1, label: "ÓTIMO" },
  { value: 2, label: "BOM" },
  { value: 3, label: "REGULAR" },
  { value: 4, label: "RUIM" },
  { value: 5, label: "PESSIMO" },
];

export default function Produtos() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [produtos, setProdutos] = useState([]);

  const currentUser = store.getState()["user"]["user"];

  const { data: produtoData } = useQuery(
    "getProdutoEstoqueById",
    () => id && getProdutoEstoqueById(id),
    {
      enabled: id ? true : false,
      onSuccess: (data) => {
        setData(data?.data);
      },
    }
  );

  const { data: produtoNome } = useQuery(
    "getProdutoNome",
    () => getProdutoByNome(),
    {
      enabled: true,
      onSuccess: (data) => {
        setProdutos(data?.data);
      },
    }
  );

  const { data: groupData } = useQuery("getGrupoProdutos", getGrupoProdutos, {
    retry: false,
    enabled: true,
  });

  const { mutate } = useMutation(id ? putProduto : postProdutoEstoque, {
    onSuccess: (data) => {
      if (id) {
        toast.success("Produto atualizado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/estoque/");
      } else {
        toast.success("Produto criado com sucesso.");
        navigate("/administrativo/patrimonio/almoxarifado/estoque/");
      }
    },
    onError: (data) => {
      if (id) {
        toast.error("Erro ao atualizar produto. Tente novamente mais tarde.");
      } else {
        toast.error("Erro ao criar produto. Tente novamente mais tarde.");
      }
    },
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { isValid, errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      numeroNotaFiscal: null,
      dataCompra: null,
      valorDaNota: null,
      produto: {
        id: null,
        nome: "",
        marca: "",
        produtoCategoriaId: null,
        tipoProduto: 0,
      },
      quantidade: null,
      valor: null,
      numeroPatrimonio: null,
      conservacao: null,
      avarias: null,
      dataValidade: null,
      observacao: "",
      criadoPor: currentUser?.usuarioLogin || "",
    },
  });

  useEffect(() => {
    const produtoSelecionado = watch("produto");
    if (produtoSelecionado?.id) {
      const produtoDetalhes = produtos.find(
        (produto) => produto.id === produtoSelecionado.id
      );
      if (produtoDetalhes) {
        setValue("marca", produtoDetalhes?.marca || "", {
          shouldValidate: true,
        });
        setValue(
          "produtoCategoriaId",
          produtoDetalhes?.produtoCategoriaId || null,
          { shouldValidate: true }
        );
        setValue("tipoProduto", produtoDetalhes?.tipoProduto || 0, {
          shouldValidate: true,
        });
      }
    }
  }, [watch("produto"), produtos, setValue]);

  useEffect(() => {
    if (id && data) {
      const formattedDate = data?.dataCompra
        ? new Date(data?.dataCompra).toISOString().split("T")[0]
        : null;

      const formattedDateValidade = data?.dataValidade
        ? new Date(data?.dataValidade).toISOString().split("T")[0]
        : null;

      const produtoNome = produtos.find(
        (produto) => produto.id === data.produtoId
      );

      setValue("numeroNotaFiscal", data?.numeroNotaFiscal);
      setValue("dataCompra", formattedDate);
      setValue("valorDaNota", data?.valorDaNota);
      setValue("produto", {
        id: data?.produtoId,
        nome: produtoNome?.nome || "",
        marca: produtoNome?.marca || "",
        produtoCategoriaId: produtoNome?.produtoCategoriaId || null,
        tipoProduto: produtoNome?.tipoProduto || 0,
      });
      setValue("quantidade", data?.quantidade);
      setValue("valor", data?.valor);
      setValue("numeroPatrimonio", data?.numeroPatrimonio);
      setValue("conservacao", data?.conservacao);
      setValue("avarias", data?.avarias);
      setValue("dataValidade", formattedDateValidade);
      setValue("observacao", data?.observacao);
      setValue("criadoPor", data?.criadoPor);
    }
  }, [id, data, setValue, produtos]);

  const onSubmit = (data) => {
    if (!data.produto || !data.produto.nome) {
      toast.error("O Nome do Produto é obrigatório.");
      return;
    }

    if (!data.marca) {
      toast.error("A Marca do Produto é obrigatória.");
      return;
    }

    if (data?.produto?.produtoCategoriaId == 0) {
      toast.error("A Categoria do Produto é obrigatória.");
      return;
    }

    if (!data.quantidade) {
      toast.error("A Quantidade é obrigatória.");
      return;
    }

    if (!data.valor) {
      toast.error("O Valor do Produto é obrigatório.");
      return;
    }

    const produtoFinal = {
      id: data?.produto.id || null,
      nome: data?.produto.nome || "",
      marca: data?.marca || "",
      produtoCategoriaId: data?.produtoCategoriaId
        ? parseInt(data?.produtoCategoriaId, 10)
        : null,
      tipoProduto: data?.tipoProduto || 0,
    };

    if (produtoFinal.tipoProduto == 1) {
      if (!data?.numeroPatrimonio) {
        toast.error("O Número de Patrimônio é obrigatório.");
        return;
      }

      if (!data.conservacao) {
        toast.error("A Conservação do Produto é obrigatória.");
        return;
      }
    } else if (produtoFinal.tipoProduto == 2) {
      if (!data.dataValidade) {
        toast.error("A Data de Validade do Produto é obrigatória.");
        return;
      }
    }

    const request = {
      produto: produtoFinal,
      numeroNotaFiscal: data.numeroNotaFiscal,
      dataCompra: data.dataCompra,
      valorDaNota: data.valorDaNota?.replace(",", "."),
      valor: data.valor?.replace(",", "."),
      marca: produtoFinal.marca,
      produtoCategoriaId: produtoFinal.produtoCategoriaId,
      quantidade: data.quantidade,
      tipoProduto: produtoFinal?.tipoProduto,
      numeroPatrimonio: data.numeroPatrimonio,
      conservacao: data.conservacao,
      avarias: data.avarias,
      dataValidade: data.dataValidade,
      observacao: data.observacao,
      criadoPor: currentUser.usuarioLogin,
    };

    if (id) {
      mutate({ id: id, data: upperCaseSimple(removeEmpty(request)) });
    } else {
      mutate(upperCaseSimple(removeEmpty(request)));
    }
  };

  const tipoProdutoWatch = watch("tipoProduto");
  const conservacaoWatch = watch("conservacao");
  const produtoCategoriaIdWatch = watch("produtoCategoriaId");

  useEffect(() => {
    if (tipoProdutoWatch == 1) {
      setValue("quantidade", 1);
    }
  }, [tipoProdutoWatch]);

  return (
    <div
      className="tab-pane fade show active"
      id="produtos"
      role="tabpanel"
      aria-labelledby="produtos-tab"
    >
      <form id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card-body">
          <div className="row">
            {!id && (
              <div className="col-md-12">
                <Alert variant="outlined" severity="warning">
                  AVISO: Atenção ao preenchimento dos campos pois uma vez
                  cadastrado os campos não podem ser editados, sendo necessário
                  a exclusão para gerar um novo lançamento.
                </Alert>
              </div>
            )}
            <div className="col-md-7 mt-4">
              <TextField
                label="Número da Nota Fiscal"
                variant="outlined"
                fullWidth
                {...register("numeroNotaFiscal")}
                error={!!errors.numeroNotaFiscal}
                helperText={errors.numeroNotaFiscal?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={id ? true : false}
              />
            </div>

            <div className="col-md-2 mt-4">
              <TextField
                type="date"
                label="Data da Compra"
                variant="outlined"
                fullWidth
                {...register("dataCompra")}
                error={!!errors.dataCompra}
                helperText={errors.dataCompra?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={id ? true : false}
              />
            </div>

            <div className="col-md-3 mt-4">
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Valor total da Nota Fiscal
                </InputLabel>
                <OutlinedInput
                  label="Valor total da Nota Fiscal"
                  variant="outlined"
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                  fullWidth
                  {...register("valorDaNota")}
                  error={!!errors.valorDaNota}
                  helperText={errors.valorDaNota?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={id ? true : false}
                />
              </FormControl>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-6">
              <Autocomplete
                freeSolo
                id="produto"
                disableClearable
                options={produtos.map((option) => ({
                  id: option.id,
                  nome: option.nome,
                }))}
                getOptionLabel={(option) => option.nome || ""}
                value={watch("produto") || null}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setValue(
                      "produto",
                      { id: null, nome: newValue },
                      { shouldValidate: true }
                    );
                  } else if (newValue && newValue.inputValue) {
                    setValue(
                      "produto",
                      { id: null, nome: newValue.inputValue },
                      { shouldValidate: true }
                    );
                  } else {
                    setValue("produto", newValue || null, {
                      shouldValidate: true,
                    });
                  }
                }}
                disabled={id ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nome do Produto"
                    variant="outlined"
                    error={!!errors.produto}
                    helperText={errors.produto?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                filterOptions={(options, state) => {
                  const filtered = options.filter((option) =>
                    option.nome
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  );

                  if (
                    state.inputValue !== "" &&
                    !filtered.some((option) => option.nome === state.inputValue)
                  ) {
                    filtered.push({
                      inputValue: state.inputValue,
                      nome: `Adicionar "${state.inputValue}"`,
                    });
                  }

                  return filtered;
                }}
              />
            </div>
            <div className="col-md-6">
              <TextField
                label="Marca"
                variant="outlined"
                fullWidth
                {...register("marca")}
                error={!!errors.marca}
                helperText={errors.marca?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={id ? true : false}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-4">
              <FormControl fullWidth error={!!errors.produtoCategoriaId}>
                <InputLabel>Categoria</InputLabel>
                <Select
                  {...register("produtoCategoriaId")}
                  label="Categoria"
                  value={produtoCategoriaIdWatch || ""}
                  disabled={id ? true : false}
                >
                  {groupData?.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.descricao}
                    </MenuItem>
                  ))}
                </Select>
                {errors.produtoCategoriaId && (
                  <FormHelperText>
                    {errors.produtoCategoriaId.message}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
            <div className="col-md-2">
              <FormControl fullWidth error={!!errors.tipoProduto}>
                <InputLabel>Tipo de Produto</InputLabel>
                <Select
                  {...register("tipoProduto")}
                  label="Tipo de Produto"
                  value={tipoProdutoWatch || ""}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={id ? true : false}
                >
                  <MenuItem value="" disabled>
                    SELECIONE
                  </MenuItem>
                  {tipoProduto?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.tipoProduto && (
                  <FormHelperText>Campo obrigatório!</FormHelperText>
                )}
              </FormControl>
            </div>

            {tipoProdutoWatch == 1 && (
              <>
                <div className="col-md-3">
                  <TextField
                    type="number"
                    label="Número do Patrimônio"
                    variant="outlined"
                    fullWidth
                    {...register("numeroPatrimonio")}
                    error={!!errors.numeroPatrimonio}
                    helperText={errors.numeroPatrimonio?.message}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={id ? true : false}
                  />
                </div>
                <div className="col-md-2">
                  <FormControl fullWidth error={!!errors.conservacao}>
                    <InputLabel>Conservação</InputLabel>
                    <Select
                      {...register("conservacao")}
                      label="Conservação"
                      value={conservacaoWatch}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={id ? true : false}
                    >
                      <MenuItem value="" disabled>
                        SELECIONE
                      </MenuItem>
                      {conservacao?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.conservacao && (
                      <FormHelperText>Campo obrigatório!</FormHelperText>
                    )}
                  </FormControl>
                </div>
                <div className="row mt-4">
                  {conservacaoWatch > 3 && (
                    <div className="col-md-12">
                      <TextField
                        label="Descrição das Avarias"
                        variant="outlined"
                        fullWidth
                        {...register("avarias")}
                        error={!!errors.avarias}
                        helperText={errors.avarias?.message}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={id ? true : false}
                      />
                    </div>
                  )}
                </div>
              </>
            )}
            {tipoProdutoWatch == 2 && (
              <div className="col-md-2">
                <TextField
                  type="date"
                  label="Data de validade"
                  variant="outlined"
                  fullWidth
                  {...register("dataValidade")}
                  error={!!errors.dataValidade}
                  helperText={errors.dataValidade?.message}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={id ? true : false}
                />
              </div>
            )}
          </div>

          <div className="row mt-4">
            <div className="col-md-2">
              <TextField
                type="number"
                label="Quantidade Unitária"
                variant="outlined"
                fullWidth
                {...register("quantidade")}
                error={!!errors.quantidade}
                helperText={errors.quantidade?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={id || tipoProdutoWatch == 1}
              />
            </div>
            <div className="col-md-2">
              <FormControl fullWidth>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Valor Unitário
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-amount"
                  label="Valor Unitário"
                  variant="outlined"
                  startAdornment={
                    <InputAdornment position="start">R$</InputAdornment>
                  }
                  fullWidth
                  {...register("valor")}
                  error={!!errors.valor}
                  helperText={errors.valor?.message}
                  disabled={id ? true : false}
                />
              </FormControl>
            </div>
            <div className="col-md-8">
              <TextField
                label="Usuário do Sistema"
                variant="outlined"
                value={currentUser.usuarioLogin}
                fullWidth
                {...register("criadoPor")}
                error={!!errors.criadoPor}
                helperText={errors.criadoPor?.message}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-md-12">
              <TextField
                label="Observações"
                variant="outlined"
                fullWidth
                {...register("observacao")}
                error={!!errors.observacao}
                helperText={errors.marca?.observacao}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={id ? true : false}
              />
            </div>
          </div>

          <div className="row mt-4"></div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="button"
            color="warning"
            variant="contained"
            onClick={() => navigate(-1)}
            style={{ marginRight: "8px" }}
          >
            Voltar
          </Button>
          {!id ? (
            <Button
              type="submit"
              color="success"
              variant="contained"
              disabled={!isValid}
            >
              {/* {id ? "Atualizar" : "Cadastrar"} */}
              Cadastrar
            </Button>
          ) : null}
        </div>
      </form>
    </div>
  );
}
