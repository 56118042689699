import api from '../index'

async function getDashboard() {
  return await api
    .get('/estatistica/prefeitura')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getDashboardProcessoSeletivo() {
  return await api
    .get('/estatistica/processoSeletivo')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getDashboardProcessoSeletivoServidores() {
  return await api
    .get('/estatistica/servidor')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getDashboardPreMatricula() {
  return await api
    .get('/estatistica/preMatricula')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getDashboardPreMatricula2024() {
  return await api
    .get('/estatistica/preMatricula2024')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

async function getDashboardRemanejamento() {
  return await api
    .get('/estatistica/remanejamento')
    .then(async (res) => {
      const { data } = res.data;
      return data;
    });
};

export {
  getDashboard,
  getDashboardProcessoSeletivo,
  getDashboardRemanejamento,
  getDashboardProcessoSeletivoServidores,
  getDashboardPreMatricula,
  getDashboardPreMatricula2024
}