import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  cincoCursos,
  experienceOptionsOutros,
  experienceOptionsPrefeitura,
} from "../data/options";

export function SecretarioEscolarForm({
  control,
  errors,
  isPrint = false,
  values,
}) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido na Prefeitura de São
          Pedro da Aldeia?
        </Typography>
        <Controller
          name="experiencePrefeitura"
          control={control}
          rules={{ required: "Experiência na Prefeitura é obrigatória." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsPrefeitura?.find(
                    (c) => c.id == values?.experiencePrefeitura
                  )?.description || ""
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsPrefeitura}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experiencePrefeitura}
                    helperText={
                      errors.experiencePrefeitura
                        ? errors.experiencePrefeitura.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography marginBottom={2}>
          Experiência profissional no cargo pretendido em outros municípios ou
          instituições privadas?
        </Typography>
        <Controller
          name="experienceOutros"
          control={control}
          rules={{
            required: "Experiência em outros municípios é obrigatória.",
          }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  experienceOptionsOutros?.find(
                    (c) => c.id == values?.experienceOutros
                  )?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={experienceOptionsOutros}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.experienceOutros}
                    helperText={
                      errors.experienceOutros
                        ? errors.experienceOutros.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Curso Superior – concluído</Typography>
        <Controller
          name="cursoSuperior"
          control={control}
          rules={{ required: "Obrigatório selecionar uma opção." }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.cursoSuperior}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.cursoSuperior}>
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.cursoSuperior && (
                  <FormHelperText>
                    {errors.cursoSuperior.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos de aperfeiçoamento de Informática na área de atuação no cargo
          pretendido e/ou Curso Pacote Office, com foco em Word e Excel Cursos
          com carga horária mínima de 50h (cinquenta horas) – por certificado - concluídos no
          período entre 2019 e a data inicial de inscrição deste processo seletivo simplificado
        </Typography>
        <Controller
          name="cursoInformatica"
          control={control}
          rules={{ required: "Selecione um curso de informática." }}
          render={({ field }) =>
            isPrint ? (
              <TextField
                value={
                  cincoCursos?.find((c) => c.id == values?.cursoInformatica)
                    ?.description || 0
                }
                fullWidth
                variant="outlined"
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <Autocomplete
                options={cincoCursos}
                value={field.value.id}
                disableClearable
                inputValue={field.value.description}
                getOptionLabel={(option) => option.description}
                isOptionEqualToValue={(option, value) =>
                  option.id === value?.id
                }
                onChange={(event, newValue) =>
                  field.onChange(newValue ? newValue.id : null)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Selecione uma opção..."
                    variant="outlined"
                    error={!!errors.cursoInformatica}
                    helperText={
                      errors.cursoInformatica
                        ? errors.cursoInformatica.message
                        : ""
                    }
                  />
                )}
              />
            )
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>
          Cursos de aperfeiçoamento em Legislação Educacional com carga horária
          mínima de 40h (quarenta horas) – concluídos no período entre 2019 e a
          data inicial de inscrição deste processo seletivo simplificado
        </Typography>
        <Controller
          name="cursoLegislacaoEducacional"
          control={control}
          rules={{ required: "Obrigatório selecionar uma opção." }}
          render={({ field }) =>
            isPrint ? (
              <RadioGroup row value={values.cursoLegislacaoEducacional}>
                <FormControlLabel value="SIM" control={<Radio />} label="Sim" />
                <FormControlLabel value="NAO" control={<Radio />} label="Não" />
              </RadioGroup>
            ) : (
              <FormControl error={!!errors.cursoLegislacaoEducacional}>
                <RadioGroup row {...field}>
                  <FormControlLabel
                    value="sim"
                    control={<Radio />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="nao"
                    control={<Radio />}
                    label="Não"
                  />
                </RadioGroup>
                {errors.cursoLegislacaoEducacional && (
                  <FormHelperText>
                    {errors.cursoLegislacaoEducacional.message}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }
        />
      </Grid>
     
    </>
  );
}
