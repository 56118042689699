import urlsAdministrativo from "./urlsAdministrativo";

const linksAdministrativo = (currentUser) => {
  console.log(currentUser)
  return {
    administrativo: {
      id: "administrativo",
      text: "ADMINISTRATIVO",
      type: "title",
      visible: true,
      itens: {
        dashboard: {
          id: "dashboard",
          text: "Dashboard",
          path: urlsAdministrativo.dashboard,
          iconName: "",
          icon: "dashboard",
          visible: true,
        },
        // Uncomment and use these items if needed
        transporte: {
          id: "transporte",
          text: "Transporte",
          path: urlsAdministrativo.administrativoTransporte,
          iconName: "",
          icon: "commute",
          visible: false,
        },
        alimentacao: {
          id: "alimentacao",
          text: "Alimentação",
          path: urlsAdministrativo.administrativoAlimentacao,
          iconName: "",
          icon: "restaurant",
          visible: false,
        },
        acompanhamentoNutricional: {
          id: "acompanhamentoNutricional",
          text: "Acomp Nutricional",
          path: urlsAdministrativo.administrativoAcompanhamentoNutricional,
          iconName: "",
          icon: "set_meal",
          visible: false,
        },
      },
    },
    // patrimonio: {
    //   id: "patrimonio",
    //   text: "PATRIMÔNIO",
    //   type: "title",
    //   visible: true,
    //   itens: {
    //     fornecedores: {
    //       id: "fornecedores",
    //       text: "Fornecedores",
    //       path: urlsAdministrativo.patrimonioControleFornecedores,
    //       iconName: "",
    //       icon: "group",
    //       visible: true,
    //     },
    //     contratos: {
    //       id: "contratos",
    //       text: "Contratos",
    //       path: urlsAdministrativo.patrimonioControleContratos,
    //       iconName: "",
    //       icon: "assignment",
    //       visible: true,
    //     },
    //     categoriaProdutos: {
    //       id: "categoriaProdutos",
    //       text: "Categoria de Produtos",
    //       path: urlsAdministrativo.patrimonioCategoriaProdutos,
    //       iconName: "",
    //       icon: "category",
    //       visible: true,
    //     },
    //     produtos: {
    //       id: "produtos",
    //       text: "Produtos",
    //       path: urlsAdministrativo.patrimonioControleProdutos,
    //       iconName: "",
    //       icon: "border_color",
    //       visible: true,
    //     },
    //     estoque: {
    //       id: "estoque",
    //       text: "Controle de estoque",
    //       path: urlsAdministrativo.patrimonioControleEstoque,
    //       iconName: "",
    //       icon: "warehouse",
    //       visible: true,
    //     },
    //     solicitacao: {
    //       id: "solicitacao",
    //       text: "Solicitações",
    //       path: urlsAdministrativo.administrativoPatrimonioControleSolicitacao,
    //       iconName: "",
    //       icon: "content_paste_go",
    //       visible: false,
    //     },
    //   },
    // },
    almoxarifado: {
      id: "almoxarifado",
      text: "ALMOXARIFADO",
      type: "title",
      visible: currentUser.moduloAdministrativoEstoque == 1,
      itens: {
        almoxarifado: {
          id: "estoque",
          text: "Estoque",
          path: urlsAdministrativo.patrimonioAlmoxarifadoEstoque,
          iconName: "",
          icon: "storefront",
          visible: true,
        },
      },
    },
    ti: {
      id: "ti",
      text: "TI",
      type: "title",
      visible: currentUser?.moduloAdministrativoTi === 1,
      itens: {
        chamados: {
          id: "chamados",
          text: "Chamados",
          path: urlsAdministrativo.patrimonioControleImpressorasChamado,
          iconName: "",
          icon: "support",
          visible: true,
        },
        impressoras: {
          id: "impressoras",
          text: "Impressoras",
          path: urlsAdministrativo.patrimonioControleImpressoras,
          iconName: "",
          icon: "print",
          visible: true,
        },
        // Uncomment and use this item if needed
        relatorio: {
          id: "relatorio",
          text: "Relatório Impressoras",
          path: urlsAdministrativo.patrimonioControleImpressorasRelatorios,
          iconName: "",
          icon: "report",
          visible: false,
        },
      },
    },
    financeiro: {
      id: "financeiro",
      text: "FINANCEIRO",
      type: "title",
      visible: currentUser?.moduloAdministrativoFinanceiro === 1,
      itens: {
        processos: {
          id: "processos",
          text: "Processos",
          path: urlsAdministrativo.financeiroProcessos,
          iconName: "",
          icon: "folder_special",
          visible: true,
        },
        contasPagar: {
          id: "contasPagar",
          text: "Contas a pagar",
          path: urlsAdministrativo.financeiroContasAPagar,
          iconName: "",
          icon: "request_quote",
          visible: true,
        },
        memorando: {
          id: "memorando",
          text: "Memorandos",
          path: urlsAdministrativo.financeiroMemorandos,
          iconName: "",
          icon: "class",
          visible: true,
        },
      },
    },
    nutricao: {
      id: "nutricao",
      text: "NUTRIÇÃO",
      type: "title",
      visible: currentUser?.moduloAdministrativoNutricao === 1,
      itens: {
        categoriaProdutos: {
          id: "categoriaProdutosn",
          text: "Categoria de Produtos",
          path: urlsAdministrativo.nutricaoCategoriaProduto,
          iconName: "",
          icon: "category",
          visible: true,
        },
        produtos: {
          id: "produtosn",
          text: "Produtos",
          path: urlsAdministrativo.nutricaoProduto,
          iconName: "",
          icon: "border_color",
          visible: true,
        },
        controlePedido: {
          id: "controlePedido",
          text: "Controle de Pedidos ",
          path: urlsAdministrativo.nutricaoControlePedido,
          iconName: "",
          icon: "assignment",
          visible: false,
        },
        movimentacoes: {
          id: "movimentacoes",
          text: "Movimentações",
          path: urlsAdministrativo.nutricaoMovimentacoes,
          iconName: "",
          icon: "warehouse",
          visible: false,
        },
        cardapio: {
          id: "cardapio",
          text: "Cardápio",
          path: urlsAdministrativo.nutricaoCardapio,
          iconName: "",
          icon: "content_paste_go",
          visible: true,
        },
      },
    },
    transporte: {
      id: "transporte",
      text: "TRANSPORTE",
      type: "title",
      visible: currentUser?.moduloAdministrativoTransporte === 1,
      itens: {
        funcionariosDashboard: {
          id: "funcionariosDashboard",
          text: "Dashboard",
          path: urlsAdministrativo.transporteDashboard,
          iconName: "",
          icon: "group",
          visible: true,
        },
        alunos: {
          id: "alunos",
          text: "Alunos",
          path: urlsAdministrativo.transporteAlunos,
          iconName: "",
          icon: "group",
          visible: true,
        },
        funcionarios: {
          id: "funcionarios",
          text: "Funcionários",
          path: urlsAdministrativo.transporteFuncionarios,
          iconName: "",
          icon: "group",
          visible: false,
        },
        veiculos: {
          id: "veiculos",
          text: "Veículos",
          path: urlsAdministrativo.transporteVeiculos,
          iconName: "",
          icon: "group",
          visible: true,
        },
        quadroHorario: {
          id: "quadroHorario",
          text: "Quadro de Horários",
          path: urlsAdministrativo.transporteQuadroHorario,
          iconName: "",
          icon: "group",
          visible: false,
        },
      },
    },
  };
};

export default linksAdministrativo;
