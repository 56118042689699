import { useState } from "react";
import { Menu, MenuItem, IconButton, Typography, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockIcon from "@mui/icons-material/Block";

const CategoriaProdutosActionMenu = ({ row, onView, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // Open the menu
  };

  const handleMenuClose = () => {
    setAnchorEl(null); // Close the menu
  };

  return (
    <>
      <IconButton onClick={handleMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          elevation: 3,
          sx: {
            borderRadius: 2,
            minWidth: 180,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            onView(row);
            handleMenuClose();
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 1,
            }}
          >
            <VisibilityIcon fontSize="small" sx={{ color: "primary.main" }} />
          </Box>
          <Typography sx={{ color: "primary.main" }}>Visualizar</Typography>
        </MenuItem>
        {/* <Divider /> */}
        <MenuItem
          onClick={() => {
            onDelete(row);
            handleMenuClose();
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: "50%",
              padding: 0.5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: 1,
            }}
          >
            <BlockIcon fontSize="small" sx={{ color: "error.main" }} />
          </Box>
          <Typography sx={{ color: "error.main" }}>Inativar</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CategoriaProdutosActionMenu;
