import React from 'react';
import { Routes, Route } from 'react-router-dom';
import urlsPreMatricula from '../../../pages/processos/preMatricula2024/urlsPreMatricula';
import urlsGestaoUnidades from '../../../pages/gestao-unidades/urlsGestaoUnidades';
import HomePreMatricula from '../../../pages/processos/preMatricula2024/homePreMatricula';
import PreMatriculaInscricoesStatus from '../../../pages/processos/preMatricula2024/private/status';
import PreMatriculaPesquisarNome from '../../../pages/processos/preMatricula2024/private/pesquisaNome';
import RelatorioConvocadosPorEscolaridade from '../../../pages/processos/preMatricula2024/private/relatorios/convocados/porEscolaridade';
import RelatorioConvocadosPorPeriodoNominal from '../../../pages/processos/preMatricula2024/private/relatorios/convocados/porPeriodoNominal';
import PreMatriculaInscricoes from '../../../pages/processos/preMatricula2024/private/index';
import RelatorioMatriculadosPorEscolaridade from '../../../pages/processos/preMatricula2024/private/relatorios/matriculados/porEscolaridade';
import RelatorioMatriculadosPorGeral from '../../../pages/processos/preMatricula2024/private/relatorios/matriculados/porGeral';
import RelatorioMatriculadosPorUnidade from '../../../pages/processos/preMatricula2024/private/relatorios/matriculados/porUnidade';
import PreMatriculaRelatorioGeral from '../../../pages/processos/preMatricula2024/private/relatorios/geral';
import PreMatriculaMatriculas from '../../../pages/processos/preMatricula2024/private/matriculas';
import PreMatriculaMatriculasEnturmar from '../../../pages/processos/preMatricula2024/private/matriculas/enturmar';
import PreMatriculaMatriculasIndeferir from '../../../pages/processos/preMatricula2024/private/matriculas/indeferir';
import RelatorioInscritosPorBairro from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porBairro';
import RelatorioInscritosPorPne from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porPne';
import RelatorioInscritosPorEscolaridade from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porEscolaridade';
import RelatorioInscritosPorGeral from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porGeral';
import RelatorioInscritosPorUnidade from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porUnidade';
import RelatorioInscritosPorNovoRede from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porNovosDaRede';
import RelatorioInscritosPorTransInterna from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porTransInterna';
import RelatorioInscritosPorTransExterna from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porTransExterna';
import RelatorioInscritosPorUnidadeNominal from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porUnidadeNominal';
import RelatorioInscritosPorPeriodoQuantitativo from '../../../pages/processos/preMatricula2024/private/relatorios/inscritos/porPeriodoQuantitativo';
import RelatorioMatriculadosPorPeriodoNominal from '../../../pages/processos/preMatricula2024/private/relatorios/matriculados/porPeriodoNominal';
import RelatorioConvocadosPorPeriodoQuantitativo from '../../../pages/processos/preMatricula2024/private/relatorios/convocados/porPeriodoQuantitativo';
import RelatorioMatriculadosPorPeriodoQuantitativo from '../../../pages/processos/preMatricula2024/private/relatorios/matriculados/porPeriodoQuantitativo';
import PreMatriculaInscricoesHistorico from '../../../pages/processos/preMatricula2024/private/historico';
import PreMatriculaAraruama from '../../../pages/formularios/preMatricula/araruama2024/pages/PreMatriculaAraruama';
import FormularioPreMatriculaVisualizacaoAraruama from '../../../pages/formularios/preMatricula/araruama2024/pages/FormularioPreMatriculaVisualizacaoAraruama';
import FormularioPreMatriculaAraruama from '../../../pages/formularios/preMatricula/araruama2024/pages/FormularioPreMatriculaAraruama';

const enable = process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'SEDUC' || process.env.REACT_APP_SECRETARIA_MUNICIPAL === 'R3MAIS';

const abertura = new Date('2024-11-25T09:00:00');

const PreMatriculaRoutes2024 = () => (
    <React.Fragment>
    {enable && (
    <>
      <Route path={urlsPreMatricula.dashboard} element={<HomePreMatricula />} />
      <Route path={urlsPreMatricula.pesquisa} element={<PreMatriculaPesquisarNome />} />
      <Route path={urlsPreMatricula.inscricoes} element={<PreMatriculaInscricoes />} />
      <Route path={urlsPreMatricula.statusId} element={<PreMatriculaInscricoesStatus />} />
      <Route path={urlsPreMatricula.formulario} element={<PreMatriculaAraruama />} />
      
      
      <Route path={urlsPreMatricula.visualizarId} element={<FormularioPreMatriculaVisualizacaoAraruama />} />

      <Route exact path={urlsGestaoUnidades.matriculas2024} element={ <PreMatriculaMatriculas gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculaEnturmarId2024} element={ <PreMatriculaMatriculasEnturmar gestao={false} />}/>
      <Route exact path={urlsGestaoUnidades.matriculasIndeferir2024} element={ <PreMatriculaMatriculasIndeferir gestao={false} />}/>
    
      
      <Route path={urlsPreMatricula.relatorioConvocadosPorEscolaridade} element={<RelatorioConvocadosPorEscolaridade />} />
      <Route path={urlsPreMatricula.relatorioConvocadosPorPeriodoNominal} element={<RelatorioConvocadosPorPeriodoNominal />} />
      <Route path={urlsPreMatricula.relatorioConvocadosPorPeriodoQuantitativo} element={<RelatorioConvocadosPorPeriodoQuantitativo />} />
      <Route path={urlsPreMatricula.relatorioConvocadosPorPeriodoNominal} element={<RelatorioConvocadosPorPeriodoNominal />} />


      <Route path={urlsPreMatricula.relatorioMatriculadosPorEscolaridade} element={<RelatorioMatriculadosPorEscolaridade />} />
      <Route path={urlsPreMatricula.relatorioMatriculadosPorGeral} element={<RelatorioMatriculadosPorGeral />} />
      <Route path={urlsPreMatricula.relatorioMatriculadosPorPeriodoNominal} element={<RelatorioMatriculadosPorPeriodoNominal />} />
      <Route path={urlsPreMatricula.relatorioMatriculadosPorPeriodoQuantitativo} element={<RelatorioMatriculadosPorPeriodoQuantitativo />} />
     

      <Route path={urlsPreMatricula.relatorioInscritosPorBairro} element={<RelatorioInscritosPorBairro />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorPne} element={<RelatorioInscritosPorPne />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorEscolaridade} element={<RelatorioInscritosPorEscolaridade />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorGeral} element={<RelatorioInscritosPorGeral />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorUnidade} element={<RelatorioInscritosPorUnidade />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorNovoRede} element={<RelatorioInscritosPorNovoRede />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorTransExterna} element={<RelatorioInscritosPorTransExterna />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorTransInterna} element={<RelatorioInscritosPorTransInterna />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorUnidadeNominal} element={<RelatorioInscritosPorUnidadeNominal />} />
      <Route path={urlsPreMatricula.relatorioInscritosPorPeriodoQuantitativo} element={<RelatorioInscritosPorPeriodoQuantitativo />} />
      <Route path={urlsPreMatricula.relatorioGeral} element={<PreMatriculaRelatorioGeral />} />
      <Route path={urlsPreMatricula.historicoId} element={<PreMatriculaInscricoesHistorico />} />

    </>
    )}
  </React.Fragment>
);

export default PreMatriculaRoutes2024;
