import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { set } from "date-fns";

export const BaseForm = ({
  control,
  errors,
  setValue,
  handleAutoCompleteForm,
  readonly = false,
  values = {},
}) => {
  function renderFields(){
    if (readonly === false){
      return
    }
    else{
      // setValue("nomeCompleto", values.nomeCompleto)
    }
  }
  return (
    <>
      <Grid item xs={12}>
        <Controller
          name="nomeCompleto"
          control={control}

          rules={{ required: "Nome completo é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Nome completo"
              variant="outlined"
              error={!!errors.nomeCompleto}              
              disabled={readonly}
              helperText={
                errors.nomeCompleto ? errors.nomeCompleto.message : ""
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="dataNascimento"
          control={control}
          rules={{ required: "Data de nascimento é obrigatória." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Data de Nascimento"
              type="date"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              error={!!errors.dataNascimento}
              disabled={readonly}
              helperText={
                errors.dataNascimento ? errors.dataNascimento.message : ""
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="cpf"
          control={control}
          rules={{ required: "CPF é obrigatório." }}
          render={({ field }) => (
            <InputMask mask="999.999.999-99" {...field} >
              {() => (
                <TextField
                  fullWidth
                  required
                  label="CPF"
                  variant="outlined"
                  error={!!errors.cpf}
                 
                  helperText={errors.cpf ? errors.cpf.message : ""}
                />
              )}
            </InputMask>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="cep"
          control={control}
          rules={{ required: "CEP é obrigatório." }}
          render={({ field }) => (
            <InputMask
              mask="99999-999"
              // disabled={readonly}
              onChange={(e) => {
                field.onChange(e); // Atualiza o valor do campo
                const formattedCep = e.target.value.replace(/\D/g, ""); // Remove caracteres não numéricos
                handleAutoCompleteForm(formattedCep); // Chama a função para buscar o endereço
              }}
            >
              {() => (
                <TextField
                  fullWidth
                  required
                  label="CEP"
                  variant="outlined"
                  // disabled={readonly}
                  error={!!errors.cep}
                  helperText={errors.cep ? errors.cep.message : ""}
                />
              )}
            </InputMask>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="endereco"
          control={control}
          rules={{ required: "Endereço é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Endereço (Logradouro)"
              variant="outlined"
              disabled={readonly}
              error={!!errors.endereco}
              helperText={errors.endereco ? errors.endereco.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="bairro"
          control={control}
          rules={{ required: "Bairro é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Bairro"
              variant="outlined"
              disabled={readonly}
              error={!!errors.bairro}
              helperText={errors.bairro ? errors.bairro.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="cidade"
          control={control}
          rules={{ required: "Cidade é obrigatória." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Cidade"
              variant="outlined"
              disabled={readonly}
              error={!!errors.cidade}
              helperText={errors.cidade ? errors.cidade.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="estado"
          control={control}
          rules={{ required: "Estado é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Estado"
              variant="outlined"
              disabled={readonly}
              error={!!errors.estado}
              helperText={errors.estado ? errors.estado.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="telefone"
          control={control}
          rules={{ required: "Telefone é obrigatório." }}
          render={({ field }) => (
            <InputMask mask="(99) 99999-9999" {...field}>
              {() => (
                <TextField
                  fullWidth
                  required
                  label="Telefone (com DDD)"
                  variant="outlined"
                  // disabled={readonly}
                  error={!!errors.telefone}
                  helperText={errors.telefone ? errors.telefone.message : ""}
                />
              )}
            </InputMask>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="rg"
          control={control}
          rules={{ required: "RG é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="RG"
              variant="outlined"
              disabled={readonly}
              error={!!errors.rg}
              helperText={errors.rg ? errors.rg.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="tituloEleitor"
          control={control}
          rules={{ required: "Título de Eleitor é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="Título de Eleitor"
              variant="outlined"
              disabled={readonly}
              error={!!errors.tituloEleitor}
              helperText={
                errors.tituloEleitor ? errors.tituloEleitor.message : ""
              }
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name="pisPasep"
          control={control}
          rules={{ required: "PIS/PASEP é obrigatório." }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              required
              label="PIS/PASEP"
              variant="outlined"
              disabled={readonly}
              error={!!errors.pisPasep}
              helperText={errors.pisPasep ? errors.pisPasep.message : ""}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Possui conta no Banco Santander?</Typography>
        <Controller
          name="contaSantander"
          control={control}
          rules={{
            required: "É obrigatório selecionar uma opção",
          }}
          render={({ field }) => (
            <FormControl error={!!errors.contaSantander}>
              <RadioGroup
                row
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
              {errors.contaSantander && (
                <FormHelperText>{errors.contaSantander.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Pessoa com Deficiência (com laudo)?</Typography>
        <Controller
          name="pessoaDeficiencia"
          control={control}
          rules={{
            required: "É obrigatório selecionar uma opção",
          }}
          render={({ field }) => (
            <FormControl error={!!errors.pessoaDeficiencia}>
              <RadioGroup
                row
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
              {errors.pessoaDeficiencia && (
                <FormHelperText>
                  {errors.pessoaDeficiencia.message}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Typography>Declaro ser pertencente à Associação de Remanescentes do Quilombo Botafogo e Caveira.</Typography>
        <Controller
          name="quilombola"
          control={control}
          rules={{
            required: "É obrigatório selecionar uma opção",
          }}
          render={({ field }) => (
            <FormControl error={!!errors.quilombola}>
              <RadioGroup
                row
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
              >
                <FormControlLabel value="sim" control={<Radio />} label="Sim" />
                <FormControlLabel value="nao" control={<Radio />} label="Não" />
              </RadioGroup>
              {errors.quilombola && (
                <FormHelperText>{errors.quilombola.message}</FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};
