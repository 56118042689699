import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Breadcrumb from "../../../components/breadcrumb";
import BaseConfiguracoes from "../../configuracoes/baseConfiguracoes";
import urlsConfiguracoes from "../../configuracoes/urlsConfiguracoes";
import { useMutation, useQuery } from 'react-query';
import { createNacionalidade, updateNacionalidade } from "../../../services/nacionalidade";
import { Autocomplete, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { getUnidades } from '../../../services/unidades';
import { getServidoresCargos } from '../../../services/cargos';
import { salvaPopup, editarPopup } from "../../../services/popups";


const PopupAdicionarPage = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { state } = useLocation();
    const [descricao, setDescricao] = useState('');
    const [tipo, setTipo] = useState('');
    const [informativoBanner, setInformativoBanner] = useState('');
    const [unidades, setUnidades] = useState([]);
    const [dataUnidades, setDataUnidades] = useState([]);
    const [unidadesSelecionadas, setUnidadesSelecionadas] = useState([]);
    const [cargos, setCargos] = useState([]);
    const [cargosSelecionados, setCargosSelecionados] = useState([]);
    const [dataCargos, setDataCargos] = useState([]);
    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);

    useQuery('unidades', getUnidades, {
        retry: true,
        onSuccess: (data) => {
            var items = ["TODAS UNIDADES"];

            data.forEach(unidade => {
                items.push(unidade.nome);
            });
            setUnidades(items);
            setDataUnidades(data);
        },
    }
    );

    useEffect(() => {
        const fetchData = async () => {
            try {
                await filtraCargos();
            } catch (error) {
                console.error('Erro ao buscar dados:', error);
            }
        };
        fetchData();

    }, []);

    const filtraCargos = async () => {
        setCargos([]);
        setCargosSelecionados([]);
        await getServidoresCargos(true, 0, 200, '').then((response) => {
            var items = ["TODOS CARGOS"];
            response.data.forEach(item => {
                items.push(item.descricao);
            });
            setDataCargos([...response.data]);
            setCargos([...new Set(items)]);
        });
    }

    function base64ToFile(base64String, fileName, mimeType) {
        // Dividir a string para remover o cabeçalho, se houver
        const base64Data = base64String.split(',')[1];
        const byteCharacters = atob(base64Data); // Decodificar a string Base64
        const byteNumbers = new Array(byteCharacters.length);

        // Converter cada caractere em um byte
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        // Criar um Blob com os dados e o tipo MIME
        const blob = new Blob([byteArray], { type: mimeType });

        // Retornar um arquivo
        return new File([blob], fileName, { type: mimeType });
    }

    const { register, handleSubmit, setValue, formState: { isValid, errors } } = useForm({
        mode: "onChange", defaultValues: {
            descricao: '',
            tipo: '',
            texto: '',
        }
    });

    const onSubmit = async (data) => {
        if (unidadesSelecionadas.length == 0) {
            toast.error('Selecione pelo menos uma unidade');
            return;
        }
        if (cargosSelecionados.length == 0) {
            toast.error('Selecione pelo menos um cargo');
            return;
        }

        if (tipo != '1' && file == null) {
            toast.error(tipo == '2' ? 'Selecione uma imagem' : 'Selecione um vídeo');
            return;
        }

        var idUnidadesSelecionadas = unidadesSelecionadas.includes('TODAS UNIDADES') ? dataUnidades.map(x => x.id) : dataUnidades
            .filter(item => {
                return unidadesSelecionadas.some(unidade => {
                    return item.nome && item.nome.toLowerCase() == unidade.toLowerCase();
                });
            })
            .map(item => item.id);

        if (cargosSelecionados.includes('TODOS CARGOS'))
            idUnidadesSelecionadas.push(0);

        var idCargosSelecionados = cargosSelecionados.includes('TODOS CARGOS') ? dataCargos.map(x => x.id) : dataCargos
            .filter(item => {
                return cargosSelecionados.some(cargo => {
                    return item.descricao && item.descricao.toLowerCase() == cargo.toLowerCase();
                });
            })
            .map(item => item.id);
        if (cargosSelecionados.includes('TODOS CARGOS'))
            idCargosSelecionados.push(0);

        const formData = new FormData();
        formData.append("id", id ?? 0);
        formData.append("unidades", JSON.stringify(idUnidadesSelecionadas));
        formData.append("cargos", JSON.stringify(idCargosSelecionados));
        formData.append("tipo", parseInt(tipo));
        formData.append("descricao", data.descricao);
        formData.append("ativo", true);
        formData.append("posicao", 0);
        formData.append("texto", data.texto);
        formData.append("arquivo", file);
        formData.append("extensao", file?.type ?? null);
        formData.append("nomeArquivo", file?.name ?? null);
        formData.append("id", id ?? 0);
        var response = id ? await editarPopup(formData) : await salvaPopup(formData);

        if (response.statusCode == 200) {
            toast.success(response.message);
            navigate(urlsConfiguracoes.popup);
        } else {
            toast.error(response.message);
        }
    };


    useMemo(() => {
        (async () => {
            if (state) {
                const { popup } = state;
                console.log(popup);
                if (id && popup && dataCargos.length > 0 && dataUnidades.length > 0) {
                    setValue('descricao', popup.descricao);
                    setValue('tipo', popup.tipo);
                    setTipo(popup.tipo.toString());
                    setValue('texto', popup.texto);
                    if (popup.tipo != 1)
                        setFile(base64ToFile(`data:${popup.extensao};base64,${popup.arquivo}`, popup.nomeArquivo, popup.extensao));
                    setCargosSelecionados(dataCargos.length == popup.cargos.length ? ["TODOS CARGOS"] : popup.cargos);
                    setUnidadesSelecionadas(dataUnidades.length == popup.unidades.length ? ["TODAS UNIDADES"] : popup.unidades);
                }
            }
        })();
    }, [dataCargos, dataUnidades]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && tipo == '3') {
            const maxSize = 20 * 1024 * 1024; // 10 MB em bytes

            if (selectedFile.size > maxSize) {
                toast.error("O arquivo excede o limite de 10 MB.");
                setFile(null);
                event.target.value = ""; // Reseta o input de arquivo
            } else {
                setFile(selectedFile);
            }
        }
        setFile(selectedFile);
    };


    return (
        <BaseConfiguracoes>
            <Breadcrumb title={'Formulário Popup'} itens={['Gestao', 'Secretarioa', 'Popups', 'Formulário']} />
            <form id='form' onSubmit={handleSubmit(onSubmit)}>
                <div className='row'>
                    <div className='col-12'>
                        <div className='card'>
                            <div className="card-body">
                                <>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <label htmlFor="descricao" className="form-label">Descrição</label>
                                            <input type="text" className="form-control" id="descricao"
                                                {...register("descricao", { required: true })} />
                                            {errors.descricao?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                        <div className='col-2'>
                                            <label htmlFor="tipo" className="form-label">Tipo</label>
                                            <select
                                                {...register("tipo", { required: true })}
                                                className="form-select" value={tipo} id="tipo" onChange={(e) => {
                                                    setTipo(e.target.value.toString());
                                                }
                                                }>
                                                <option value="">Selecione</option>
                                                <option value="1">Texto</option>
                                                <option value="2">Imagem</option>
                                                <option value="3">Video</option>
                                            </select>
                                            {errors.tipo?.type === 'required' && <div role='alert' className="invalid-feedback d-block">Campo obrigatório!</div>}
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <label htmlFor="descricao" className="form-label">Unidades</label>
                                            <Autocomplete
                                                multiple
                                                options={unidades}
                                                getOptionLabel={(option) => option}
                                                disableCloseOnSelect
                                                value={unidadesSelecionadas}
                                                onChange={(event, newValue) => {
                                                    if (event.target.textContent == "TODAS UNIDADES" || newValue.indexOf('TODAS UNIDADES') > -1)
                                                        setUnidadesSelecionadas(["TODAS UNIDADES"]);
                                                    else
                                                        setUnidadesSelecionadas(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Selecione as unidades"
                                                        placeholder="Selecione as unidades"
                                                    />
                                                )}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor padrão da borda
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor da borda ao passar o mouse
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor da borda ao focar
                                                    },
                                                }}
                                            />

                                        </div>

                                    </div>

                                    <div className='row'>
                                        <div className='col-12'>
                                            <label htmlFor="descricao" className="form-label">Cargos</label>
                                            <Autocomplete
                                                multiple
                                                options={cargos}
                                                getOptionLabel={(option) => option}
                                                disableCloseOnSelect
                                                value={cargosSelecionados}
                                                onChange={(event, newValue) => {
                                                    if (event.target.textContent == "TODOS CARGOS" || newValue.indexOf('TODOS CARGOS') > -1)
                                                        setCargosSelecionados(["TODOS CARGOS"]);
                                                    else
                                                        setCargosSelecionados(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Selecione os cargos"
                                                        placeholder="Selecione os cargos"
                                                    />
                                                )}
                                                sx={{
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor padrão da borda
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor da borda ao passar o mouse
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: '#e3e6ea', // Define a cor da borda ao focar
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {
                                        tipo == '1' ?
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label htmlFor="descricao" className="form-label">Informe o Texto a ser exibido no banner</label>
                                                    <textarea
                                                        {...register("texto", { required: tipo == '1' ? true : false })} className="form-control" rows={4}>
                                                    </textarea>
                                                </div>
                                            </div> : tipo == '2' || tipo == '3' ?
                                                <div className="row" style={{ marginTop: 10 }}>
                                                    <div className="col-md-12">
                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            sx={{ textTransform: 'none' }}
                                                        >
                                                            Escolher arquivo
                                                            <input
                                                                multiple={false}
                                                                type="file"
                                                                accept={tipo == '2' ? "image/*" : "video/*"}
                                                                hidden
                                                                onChange={(e) => handleFileChange(e)}
                                                            />
                                                        </Button>
                                                        <Button style={{ display: file ? 'inline' : 'none' }}
                                                            variant="contained"
                                                            component="label"
                                                            color="error"
                                                            sx={{ textTransform: 'none', marginLeft: '5px' }}
                                                            onClick={() => setFile(null)}
                                                        >Remover arquivo</Button>

                                                        {/* Exibe informações do arquivo selecionado */}
                                                        {file && (
                                                            <div style={{ display: 'grid', placeItems: 'center', marginTop: 10 }}>
                                                                {tipo == '2' ?
                                                                    <img height={350} width="auto" src={URL.createObjectURL(file)}></img> :
                                                                    <video
                                                                        src={URL.createObjectURL(file)}
                                                                        controls
                                                                        width="350"
                                                                        style={{ display: "block", marginTop: "10px" }}
                                                                    >
                                                                    </video>
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div> : <></>
                                    }



                                </>
                            </div>
                            <div className='card-footer text-end'>
                                <button type='submit' className='btn btn-success'>
                                    {id ? 'Atualizar' : 'Cadastrar'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </BaseConfiguracoes>
    )

};

export default PopupAdicionarPage;