import { toast } from 'react-toastify'
import api from '../..';

async function listarMunicipios(tipo = 0) {
  return await api
    .get(`processoSeletivo/unidade/municipios?tipo=${tipo}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

async function listarPolos() {
    return await api
      .get(`processoSeletivo/unidade/polos`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function listarBairros() {
    return await api
      .get(`processoSeletivo/unidade/bairros`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };


async function listarEscolas(anoLetivo, escolaridadeId) {
  return await api
    .get(`processoSeletivo/unidade?anoLetivo=${anoLetivo}&escolaridadeId=${escolaridadeId}`)
    .then(async (res) => {
      const { data } = res;
      return data;
    });
};

  async function listarInscricoes(municipioId, poloId, escolaOrigem, deficiente, status, pesquisaCPF, pesquisarAluno, pesquisarResponsavel, tipoInscricao, skip, limit) {
    return await api
      .get(`processoSeletivo/inscricao?municipioId=${municipioId}&poloId=${poloId}&escolaOrigem=${escolaOrigem}&deficiente=${deficiente}&status=${status}&cpf=${pesquisaCPF}&nomeAluno=${pesquisarAluno}&nomeResponsavel=${pesquisarResponsavel}&tipoInscricao=${tipoInscricao}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function listarInscricoesServidores(cargo, status, cpf, nome, skip, limit, deficiente, recurso) {
    return await api
      .get(`processoSeletivo/inscricao/servidor?cargo=${cargo}&status=${status}&cpf=${cpf}&nome=${nome}&skip=${skip}&limit=${limit}&deficiente=${deficiente}&recurso=${recurso}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };


  async function listarInscricoesRemanejamento(escolaridadeId, bairroId, escolaOrigemId, escolaPretendidaId1, escolaPretendidaId2, deficiente, status, skip, limit) {
    return await api
      .get(`processoSeletivo/inscricao/remanejamento?escolaridadeId=${escolaridadeId}&bairroId=${bairroId}&escolaOrigemId=${escolaOrigemId}&escolaPretendidaId1=${escolaPretendidaId1}&escolaPretendidaId2=${escolaPretendidaId2}&deficiente=${deficiente}&status=${status}&skip=${skip}&limit=${limit}`)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function adicionarInscricao(data) {
    return await api
      .post(`processoSeletivo/inscricao`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function indeferirInscricao(data) {
    return await api
      .post(`processoSeletivo/inscricao/status`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function indeferirInscricaoRemanejamento(data) {
    return await api
      .post(`processoSeletivo/inscricao/remanejamento/status`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function atualizarStatusInscricaoServidor(data) {
    return await api
      .post(`processoSeletivo/inscricao/servidor/status`, data)
      .then(async (res) => {
        const { data } = res;
        return data;
      });
  };

  async function listaAnexo(id) {
    // Configura o axios para tratar a resposta como blob
    return await api
      .get(`arquivo/${id}`, {
        responseType: 'blob', // Especifica que a resposta é um blob (binário)
      })
      .then(async (res) => {
        return res.data; // O res.data será um Blob
      });
  }
  

  async function downloadAnexo(id, nome, anexoId) {

    let nomeArquivo = id + "_" + nome.split(' ').join('_') + '.pdf'
    return await api
    .get(`arquivo/${anexoId}`,
    { responseType: 'arraybuffer' }, 
    { headers: { 'Content-Type': 'blob' } })
    .then((response) => {
      let fileName = nomeArquivo;
      if (response.code != 'ERR_BAD_RESPONSE'){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // IE variant
          window.navigator.msSaveOrOpenBlob(
            new Blob([response.data], {
              type: 'application/pdf',
              encoding: 'UTF-8'
            }),
            fileName
          );
        } else {
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/pdf',
              encoding: 'UTF-8'
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
        }

      }
      
    }
    ).catch(response => {
      // if (response.code == 'ERR_BAD_RESPONSE') {
        toast.error('Erro ao baixar anexo, arquivo corrompido.')
      //  }
       }
    )
   
};
  

  async function gerarRelatorioInscricoes(municipioId, poloId, escolaOrigem, deficiente, status, pesquisaCPF, pesquisarAluno, pesquisarResponsavel, tipoInscricao) {
    return await api
    .get(`relatorio/inscricoes?municipioId=${municipioId}&poloId=${poloId}&escolaOrigem=${escolaOrigem}&deficiente=${deficiente}&status=${status}&cpf=${pesquisaCPF}&nomeAluno=${pesquisarAluno}&nomeResponsavel=${pesquisarResponsavel}&tipoInscricao=${tipoInscricao}`,
    { responseType: 'arraybuffer' }, 
    { headers: { 'Content-Type': 'blob' } })
    .then((response) => {
      let fileName = `inscricoes-process-seletivo.xlsx`;
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE variant
        window.navigator.msSaveOrOpenBlob(
          new Blob([response.data], {
            type: 'application/pdf',
            encoding: 'UTF-8'
          }),
          fileName
        );
      } else {
        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            encoding: 'UTF-8'
          })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    });
};

async function gerarRelatorioInscricoesServidores(cargo, status, cpf, nome, skip, limit, deficiente, recurso) {
  return await api
  .get(`relatorio/inscricoes/servidores?cargo=${cargo}&status=${status}&cpf=${cpf}&nome=${nome}&skip=${skip}&limit=${limit}&deficiente=${deficiente}&recurso=${recurso}`,
  { responseType: 'arraybuffer' }, 
  { headers: { 'Content-Type': 'blob' } })
  .then((response) => {
    let fileName = `inscricoes-processo-seletivo-servidores.xlsx`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'application/pdf',
          encoding: 'UTF-8'
        }),
        fileName
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
};
  
async function gerarRelatorioInscricoesRemanejamento(escolaridadeId, bairroId, escolaOrigemId, escolaPretendidaId1, escolaPretendidaId2, deficiente, status, skip, limit) {
  return await api
  .get(`relatorio/inscricoes/remanejamento?escolaridadeId=${escolaridadeId}&bairroId=${bairroId}&escolaOrigemId=${escolaOrigemId}&escolaPretendidaId1=${escolaPretendidaId1}&escolaPretendidaId2=${escolaPretendidaId2}&deficiente=${deficiente}&status=${status}&skip=${skip}&limit=${limit}`,
  { responseType: 'arraybuffer' }, 
  { headers: { 'Content-Type': 'blob' } })
  .then((response) => {
    let fileName = `inscricoes-remanejamento.xlsx`;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // IE variant
      window.navigator.msSaveOrOpenBlob(
        new Blob([response.data], {
          type: 'application/pdf',
          encoding: 'UTF-8'
        }),
        fileName
      );
    } else {
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          encoding: 'UTF-8'
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  });
};

async function postInscricao(data) {
  return await api
    .post('processoSeletivo/inscricao', data)
    .then(async (res) => {
      console.log(res)
      const data = res.data;

      return data;
    });
};

async function postInscricaoServidor(data) {
  return await api
    .post('processoSeletivo/inscricao/servidor', data)
    .then(async (res) => {
      console.log(res)
      const data = res.data;

      return data;
    });
};

async function postInscricaoServidorRecurso(data) {
  return await api
    .post('processoSeletivo/inscricao/servidor/recurso', data)
    .then(async (res) => {
      console.log(res)
      const data = res.data;

      return data;
    });
};

async function postInscricaoRemanejamento(data) {
  return await api
    .post('processoSeletivo/inscricao/remanejamento', data)
    .then(async (res) => {
      console.log(res)
      const data = res.data;

      return data;
    });
};

async function postListaEspera(data) {
  return await api
    .post('listaespera', data)
    .then(async (res) => {
      const data = res.data;
      return data;
    });
};

  export {
    listarMunicipios,
    listarPolos,
    adicionarInscricao,
    listarInscricoes,
    gerarRelatorioInscricoes,
    indeferirInscricao,
    listaAnexo,
    downloadAnexo,
    listarEscolas,
    listarBairros,
    listarInscricoesRemanejamento,
    gerarRelatorioInscricoesRemanejamento,
    indeferirInscricaoRemanejamento,
    listarInscricoesServidores,
    atualizarStatusInscricaoServidor,
    gerarRelatorioInscricoesServidores,
    postInscricao,
    postInscricaoRemanejamento,
    postInscricaoServidor,
    postInscricaoServidorRecurso,
    postListaEspera
  }