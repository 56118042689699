import api from '../'


async function getPopups(skip, limit) {
  var url = `Popup/listapopups?skip=${skip}&limit=${limit}`
  return await api
    .get(url)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
};

async function deletePopup(id) {
  var url = `Popup/delete/${id}`
  return await api
    .delete(url)
    .then(async (res) => {
      const { data } = res.data;
      return res.data;
    });
};

async function salvaPopup(data) {
    try {
      const res = await api.post(`Popup/criapopup`, data);
      return res.data;
    } catch (error) {
      console.error("Erro ao salvar o popup:", error.response?.data || error.message);
      return { success: false, error: error.response?.data || error.message };
    }
  }

  async function editarPopup(data) {
      try {
        const res = await api.put(`Popup/editarpopup`, data);
        return res.data;
      } catch (error) {
        console.error("Erro ao editar o popup:", error.response?.data || error.message);
        return { success: false, error: error.response?.data || error.message };
      }
    }

export {
    salvaPopup,
    getPopups,
    deletePopup,
    editarPopup
}