import React, { useEffect } from "react";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import dayjs from "dayjs"; // Import Day.js for handling dates
import "dayjs/locale/pt-br"; // Import Portuguese locale
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { carteiraDeNascimento } from "../data/carteiraDeNascimento";
import { escolhasBinarias } from "../data/escolhasBinarias";
import { escolaridadesOptions } from "../data/escolaridadesOptions";


dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const Etapa3 = ({ data, onChange, setValidate }) => {


  const parseDateDDMMYYYY = (dateString) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day); // JavaScript months are 0-based
  };

    const [validateDateOfBirth, setValidateDateOfBirth] = React.useState(false);
    const ranges = [
        { label: "CRECHE I (6 MESES A 11 MESES)", start: dayjs("2024-04-01"), end: dayjs("2024-09-30") },
        { label: "CRECHE II (1 ANO A 1 ANO E 11 MESES)", start: dayjs("2023-04-01"), end: dayjs("2024-03-31") },
        { label: "CRECHE III (2 ANOS A 2 ANOS E 11 MESES)", start: dayjs("2022-04-01"), end: dayjs("2023-03-31") },
        { label: "CRECHE IV (3 ANOS A 3 ANOS E 11 MESES)", start: dayjs("2021-04-01"), end: dayjs("2022-03-31") },
        { label: "PRÉ I (4 ANOS A 4 ANOS E 11 MESES)", start: dayjs("2020-04-01"), end: dayjs("2021-03-31") },
        { label: "PRÉ II (5 ANOS A 5 ANOS E 11 MESES)", start: dayjs("2019-04-01"), end: dayjs("2020-03-31") },
        { label: "1º ANO", start: dayjs("2000-01-01"), end: dayjs("2019-03-31") },
        { label: "2º ANO", start: dayjs("2000-04-01"), end: dayjs("2018-03-31") },
        { label: "3º ANO", start: dayjs("2000-04-01"), end: dayjs("2017-03-31") },
        { label: "4º ANO", start: dayjs("2000-04-01"), end: dayjs("2016-03-31") },
        { label: "5º ANO", start: dayjs("2000-04-01"), end: dayjs("2015-03-31") },
        { label: "6º ANO", start: dayjs("2000-04-01"), end: dayjs("2014-03-31") },
        { label: "7º ANO", start: dayjs("2000-04-01"), end: dayjs("2013-03-31") },
        { label: "8º ANO", start: dayjs("2000-04-01"), end: dayjs("2012-03-31") },
        { label: "9º ANO", start: dayjs("2000-04-01"), end: dayjs("2011-03-31") },
      ];

      const [errorDateOfBirth, setErrorDateOfBirth] = React.useState("");

      useEffect(() => {
        // Define the validation function for this step
        setValidate(() => () => {


          // const dateBirthTo = parseDateDDMMYYYY(data.passo3DataNascimento);

          // const dateOfBirth = data.passo3DataNascimento
          //   ? dayjs(parseDateDDMMYYYY(data.passo3DataNascimento))
          //   : null;
      
          // if (dateOfBirth && !dateOfBirth.isValid()) {
          //   return false; // If dateOfBirth is invalid, return false
          // }
      
          // const today = dayjs();
          // const filteredEscolaridades = escolaridadesOptions[data?.passo1Curso] || []; // Default to an empty array if undefined
          // const anoText = filteredEscolaridades.find((option) => option.id === data?.passo2Escolaridade)?.label || "--";
      
          let validEstudanteRedeExterna = false;
      
          // Validate external transfer
          if (!data.passo3EstudanteRedeExterna) {
            validEstudanteRedeExterna = true;
          } else {
            validEstudanteRedeExterna =
              !!data.passo3UnidadeRedeExterna &&
              !!data.passo3CidadeRedeExterna &&
              !!data.passo3EstadoRedeExterna;
          }
      
          // // Determine validation based on `anoText`
          // if (anoText.includes("FASE")) {
          //   validateDateOfBirth = dateOfBirth
          //     ? today.diff(dateOfBirth, "year") >= 15 && dateOfBirth.year() <= 1900
          //     : false;
          // } else {
          //   // Match the correct range for `anoText`
          //   const selectedRange = ranges.find((range) => range.label === anoText);
      
          //   validateDateOfBirth = dateOfBirth && selectedRange
          //     ? dateOfBirth.isSameOrAfter(selectedRange.start, "day") &&
          //       dateOfBirth.isSameOrBefore(selectedRange.end, "day")
          //     : false;
          // }  
          
          return (
            data.passo3NomeCompleto !== "" &&
            data.passo3DataNascimento &&
            data.passo3Filiacao1 !== "" &&
            validEstudanteRedeExterna &&
            validateDateOfBirth
          );
        });
      }, [data, setValidate]);
      
  useEffect(() => {
    if (!data.passo3EstudanteRedeExterna) {
    onChange("passo3EstadoRedeExterna", "");
    onChange("passo3CidadeRedeExterna", "");
    onChange("passo3UnidadeRedeExterna", "");
    }
  }, [data.passo3EstudanteRedeExterna]);

  const handleDateInputChange = (event) => {
    const maskedValue = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .replace(/(\d{2})(\d)/, "$1/$2")
      .substr(0, 10);
    onChange("passo3DataNascimento", maskedValue);

    if (maskedValue.length === 10) {
      validateDate(maskedValue);
    } else {
      setErrorDateOfBirth("Data incompleta.");
    }
  };

  const validateDate = (dateString) => {
    const dateRegex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

    const parsedDateConvert = parseDateDDMMYYYY(dateString);

  if (!dateRegex.test(dateString)) {
    setErrorDateOfBirth("Data inválida. Use o formato DD/MM/YYYY.");
    return false;
  }


  // Parse the date using Day.js
  const parsedDate = dayjs(parsedDateConvert);

  if (!parsedDate.isValid()) {
    setErrorDateOfBirth("Data inválida.");
    setValidateDateOfBirth(false);
    return false;
  }

    const filteredEscolaridades = escolaridadesOptions[data?.passo1Curso] || [];
    const anoText = filteredEscolaridades.find((option) => option.id === data?.passo2Escolaridade)?.label || "--";
    const selectedRange = ranges.find((range) => range.label === anoText);

    const today = dayjs();
    const idade = today.diff(parsedDate, "year");
    if (data.passo1Curso === 4 && idade < 15) {
      setValidateDateOfBirth(false);
      setErrorDateOfBirth("Idade em desacordo com a escolaridade. Permitido a partir de 15 anos.");
      return false;
    }

    if (selectedRange) {
      const isWithinRange =
        parsedDate.isSameOrAfter(selectedRange.start, "day") &&
        parsedDate.isSameOrBefore(selectedRange.end, "day");

      if (!isWithinRange) {
        setErrorDateOfBirth(`Idade em desacordo com a escolaridade. Permitido nascidos até ${selectedRange.end.format("DD/MM/YYYY")}.`);
        setValidateDateOfBirth(false);
        return false;
      }
      
      
    }

    setErrorDateOfBirth("");
    setValidateDateOfBirth(true);
    return true;
  };

  return (
    <Box sx={{ padding: 2 }}>
     

      <Grid container spacing={2}>
        {/* Nome Completo */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Nome Completo do Aluno"
            fullWidth
            required
            value={data?.passo3NomeCompleto || ""}
            onChange={(e) => onChange("passo3NomeCompleto", e.target.value)}
          />
        </Grid>

        {/* Data de Nascimento */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Data de Nascimento"
            fullWidth
            required
            value={data?.passo3DataNascimento || ""}
            onChange={handleDateInputChange}
            error={!!errorDateOfBirth}
            helperText={errorDateOfBirth}
            inputProps={{
              maxLength: 10,
              placeholder: "DD/MM/YYYY",
            }}
          />
        </Grid>

      {/* Numero Carteirinha do SUS */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Número Carteirinha do SUS"
            fullWidth
            value={data?.passo3CarteirinhaSus || ""}
            onChange={(e) => onChange("passo3CarteirinhaSus", e.target.value)}
          />
        </Grid>

        {/* CPF do Aluno */}
        <Grid item md={6} xs={12}>
          <TextField
            label="CPF do Aluno"
            fullWidth
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
            value={data?.passo2CpfAluno || ""}
          />
        </Grid>

        {/* Modelo Certidão de Nascimento */}
        <Grid item md={6} xs={12}>
          <Autocomplete
            options={carteiraDeNascimento}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="Modelo Certidão de Nascimento"
                variant="outlined"
                // required
              />
            )}
            value={
              carteiraDeNascimento.find(
                (option) => option.id === data.passo3ModeloCertidaoNascimento
              ) || null
            }
            onChange={(e, value) =>
              onChange(
                "passo3ModeloCertidaoNascimento",
                value ? value.id : null
              )
            }
          />
        </Grid>

        {/* Documento Certidão de Nascimento */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Documento Certidão de Nascimento"
            fullWidth
            // required
            value={data?.passo3DocumentoCertidaoNascimento || ""}
            onChange={(e) =>
              onChange("passo3DocumentoCertidaoNascimento", e.target.value)
            }
          />
        </Grid>

        {/* Filiação 1 */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Filiação 1"
            fullWidth
            required
            value={data?.passo3Filiacao1 || ""}
            onChange={(e) => onChange("passo3Filiacao1", e.target.value)}
          />
        </Grid>

        {/* Filiação 2 */}
        <Grid item md={6} xs={12}>
          <TextField
            label="Filiação 2"
            fullWidth
            value={data?.passo3Filiacao2 || ""}
            onChange={(e) => onChange("passo3Filiacao2", e.target.value)}
          />
        </Grid>

        {/* Estudante Rede Externa */}
        <Grid item md={12} xs={12}>
          <Autocomplete
            options={escolhasBinarias}
            // disabled={true}
            getOptionLabel={(option) => option.label}
            fullWidth
            renderInput={(params) => (
              <TextField
                {...params}
                label="O estudante vem de transferência externa?"
                variant="outlined"
               
                required
              />
            )}
            value={
              escolhasBinarias.find(
                (option) => option.id === data.passo3EstudanteRedeExterna
              ) || null
            }
            onChange={(e, value) =>
              onChange("passo3EstudanteRedeExterna", value ? value.id : null)
            }
          />
        </Grid>

        {/* Conditionally Render Fields if Transferência Externa is True */}
        {data.passo3EstudanteRedeExterna && (
          <>
            <Grid item md={4} xs={12}>
              <TextField
                label="Unidade da Rede Externa"
                fullWidth
                value={data?.passo3UnidadeRedeExterna || ""}
                onChange={(e) =>
                  onChange("passo3UnidadeRedeExterna", e.target.value)
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Cidade da Rede Externa"
                fullWidth
                value={data?.passo3CidadeRedeExterna || ""}
                onChange={(e) =>
                  onChange("passo3CidadeRedeExterna", e.target.value)
                }
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                label="Estado da Rede Externa"
                fullWidth
                value={data?.passo3EstadoRedeExterna || ""}
                onChange={(e) =>
                  onChange("passo3EstadoRedeExterna", e.target.value)
                }
              />
            </Grid>
          </>
        )}

         <Grid item md={12} xs={12}>
         <TableContainer component={Paper} sx={{ marginBottom: 4 }}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Escolaridade</TableCell>
        <TableCell align="center">Data Nascimento Mínima</TableCell>
        <TableCell align="center">Data Nascimento Máxima</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {ranges.map((range) => (
        <TableRow key={range.label}>
          <TableCell>{range.label}</TableCell>
          <TableCell align="center">
          {range.start.format("DD/MM/YYYY") === "01/04/2000" ? "-" : range.start.format("DD/MM/YYYY")}
        </TableCell>
          <TableCell align="center">{range.end.format("DD/MM/YYYY")}</TableCell>
        </TableRow>
      ))}
      {/* Add the EJA row */}
      <TableRow>
        <TableCell>EJA</TableCell>
        <TableCell align="center">A partir de 15 anos</TableCell>
        <TableCell align="center">-</TableCell>
      </TableRow>
    </TableBody>
  </Table>
</TableContainer>

        </Grid> 
      </Grid>
     
    </Box>
  );
};

export default Etapa3;
