export const servidoresCargos = [
    { id: 1, description: 'AGENTE ADMINISTRATIVO ****' },
    { id: 2, description: 'AJUDANTE DE MECÂNICO DE MÁQUINA PESADA' },
    { id: 3, description: 'ASSESSOR ESPECIAL II' },
    { id: 4, description: 'ASSESSOR ESPECIAL III' },
    { id: 5, description: 'ASSESSOR II' },
    { id: 6, description: 'ASSESSOR III' },
    { id: 7, description: 'ASSESSOR IV' },
    { id: 8, description: 'ASSESSOR V' },
    { id: 9, description: 'AUX. SECRETARIA ESCOLAR' },
    { id: 10, description: 'AUX. SECRETARIA ESCOLAR ****' },
    { id: 11, description: 'AUXILIAR DE CRECHE' },
    { id: 12, description: 'AUXILIAR DE GESTÃO' },
    { id: 13, description: 'AUXILIAR DE GESTÃO ESCOLAR' },
    { id: 14, description: 'AUXILIAR DE SERVIÇOS GERAIS **' },
    { id: 15, description: 'AUXILIAR SECRETARIA ESCOLAR' },
    { id: 16, description: 'BOMBEIRO HIDRÁULICO' },
    { id: 17, description: 'CHEFE DE SERVIÇO' },
    { id: 18, description: 'CHEFE DE SETOR' },
    { id: 19, description: 'COZINHEIRA ****' },
    { id: 20, description: 'CUIDADOR DE ALUNO' },
    { id: 21, description: 'CUIDADOR DE ALUNO ESPECIAL' },
    { id: 22, description: 'CUIDADOR DE ALUNO ESPECIAL - CONTRATO' },
    { id: 23, description: 'FONOAUDIÓLOGA ****' },
    { id: 24, description: 'INSPETOR DE ALUNO' },
    { id: 25, description: 'INSPETOR DE ALUNO ****' },
    { id: 26, description: 'INSPETOR ESCOLAR' },
    { id: 27, description: 'INSTRUTOR DE ARTESANATO' },
    { id: 28, description: 'INSTRUTOR DE CAPOEIRA' },
    { id: 29, description: 'INSTRUTOR DE DANÇA' },
    { id: 30, description: 'MERENDEIRA' },
    { id: 31, description: 'MERENDEIRA ****' },
    { id: 32, description: 'MONITOR DE TRANSPORTE' },
    { id: 33, description: 'MONITOR DE TRANSPORTE ESCOLAR' },
    { id: 34, description: 'MOTORISTA ****' },
    { id: 35, description: 'MOTORISTA CATEGORIA D ou E' },
    { id: 36, description: 'MOTORISTA CLASSE D (CONTRATO)' },
    { id: 37, description: 'MOTORISTA.' },
    { id: 38, description: 'NUTRICIONISTA.' },
    { id: 39, description: 'ORIENTADOR EDUCACIONAL' },
    { id: 40, description: 'ORIENTADOR PEDAGÓGICO' },
    { id: 41, description: 'PORTEIRO' },
    { id: 42, description: 'PROFESSOR DOC I' },
    { id: 43, description: 'PROFESSOR DOC I DR' },
    { id: 44, description: 'PROFESSOR DOC II' },
    { id: 45, description: 'PROFESSOR DOC II AR' },
    { id: 46, description: 'PROFESSOR DOC II E' },
    { id: 47, description: 'PROFESSOR ESPEC EDUCACAO' },
    { id: 48, description: 'PSICOPEDAGOGO' },
    { id: 49, description: 'RECREADOR DE 1º AO 5º - E.F.' },
    { id: 50, description: 'RECREADOR DE 1º AO 5º - E.F.I.' },
    { id: 51, description: 'SECRETARIO DE ESCOLA' },
    { id: 52, description: 'SECRETARIO DE ESCOLA ****' },
    { id: 53, description: 'SECRETARIO MUN ADJUNTO ADMINISTRATIVO' },
    { id: 54, description: 'SECRETARIO MUN ADJUNTO PEDAGÓGICO' },
    { id: 55, description: 'SECRETARIO MUNICIPAL EDUCACAO' },
    { id: 56, description: 'SERVENTE' },
    { id: 57, description: 'SUPERVISOR PEDAGOGICO' },
    { id: 58, description: 'TESOUREIRO' },
    { id: 59, description: 'VIGIA' },
  ];
  
  
  export const regimesServidores = [
      { id: 1, description: "C COMISSAO" },
      { id: 2, description: "CONTRATADOS" },
      { id: 3, description: "ESTATUTARIO" },
      {id: 4, description: "CEDIDO" }
    ]
  
  export const brazilianStates = [
      "", "AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS",
      "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC",
      "SP", "SE", "TO"
    ];
  
  export const nacionalidades = [
      { id: 0, value: '' },
      { id: 1, value: 'AFEGÃO' },
      { id: 2, value: 'ALEMÃO' },
      { id: 3, value: 'AMERICANO' },
      { id: 4, value: 'ANGOLANO' },
      { id: 5, value: 'ANTIGUANO' },
      { id: 6, value: 'ÁRABE, EMIRATENSE' },
      { id: 7, value: 'ARGÉLIA' },
      { id: 8, value: 'ARGENTINO' },
      { id: 9, value: 'ARMENO' },
      { id: 10, value: 'AUSTRALIANO' },
      { id: 11, value: 'AUSTRÍACO' },
      { id: 12, value: 'BAHAMENSE' },
      { id: 13, value: 'BANGLADESH' },
      { id: 14, value: 'BARBADIANO, BARBADENSE' },
      { id: 15, value: 'BECHUANO' },
      { id: 16, value: 'BELGA' },
      { id: 17, value: 'BELIZENHO' },
      { id: 18, value: 'BOLIVIANO' },
      { id: 19, value: 'BRASILEIRO' },
      { id: 20, value: 'BRITÂNICO' },
      { id: 21, value: 'CAMARONENSE' },
      { id: 22, value: 'CANADENSE' },
      { id: 23, value: 'CHILENO' },
      { id: 24, value: 'CHINÊS' },
      { id: 25, value: 'CINGALÊS' },
      { id: 26, value: 'COLOMBIANO' },
      { id: 27, value: 'COMORENSE' },
      { id: 28, value: 'COSTARRIQUENHO' },
      { id: 29, value: 'CROATA' },
      { id: 30, value: 'CUBANO' },
      { id: 31, value: 'DINAMARQUÊS' },
      { id: 32, value: 'DOMINICANA' },
      { id: 33, value: 'DOMINICANO' },
      { id: 34, value: 'EGÍPCIO' },
      { id: 35, value: 'EQUATORIANO' },
      { id: 36, value: 'ESCOCÊS' },
      { id: 37, value: 'ESLOVACO' },
      { id: 38, value: 'ESLOVENO' },
      { id: 39, value: 'ESPANHOL' },
      { id: 40, value: 'ESTE - TIMORENSE, MAUBERE' },
      { id: 41, value: 'FRANCÊS' },
      { id: 42, value: 'GALÊS' },
      { id: 43, value: 'GANÉS' },
      { id: 44, value: 'GRANADINO' },
      { id: 45, value: 'GREGO' },
      { id: 46, value: 'GUATEMALTECO' },
      { id: 47, value: 'GUIANENSE' },
      { id: 48, value: 'GUIANÊS' },
      { id: 49, value: 'HAITIANO' },
      { id: 50, value: 'HOLANDÊS' },
      { id: 51, value: 'HONDURENHO' },
      { id: 52, value: 'HÚNGARO' },
      { id: 53, value: 'IEMENITA' },
      { id: 54, value: 'INDIANO' },
      { id: 55, value: 'INDONÉSIO' },
      { id: 56, value: 'INGLÊS' },
      { id: 57, value: 'IRANIANO' },
      { id: 58, value: 'IRAQUIANO' },
      { id: 59, value: 'IRLANDÊS' },
      { id: 60, value: 'ISRAELITA' },
      { id: 61, value: 'ITALIANO' },
      { id: 62, value: 'JAMAICANO' },
      { id: 63, value: 'JAPONÊS' },
      { id: 64, value: 'LÍBIO' },
      { id: 65, value: 'MALAIO' },
      { id: 66, value: 'MARFINENSE' },
      { id: 67, value: 'MARROQUINO' },
      { id: 68, value: 'MEXICANO' },
      { id: 69, value: 'MOÇAMBICANO' },
      { id: 70, value: 'NEOZELANDÊS' },
      { id: 71, value: 'NEPALÊS' },
      { id: 72, value: 'NICARAGUENSE' },
      { id: 73, value: 'NIGERIANO' },
      { id: 74, value: 'NORTE-COREANO, COREANO' },
      { id: 75, value: 'NORUEGO' },
      { id: 76, value: 'OMANENSE' },
      { id: 77, value: 'PALESTINO' },
      { id: 78, value: 'PANAMENHO' },
      { id: 79, value: 'PAQUISTANÊS' },
      { id: 80, value: 'PARAGUAIO' },
      { id: 81, value: 'PERUANO' },
      { id: 82, value: 'POLONÊS' },
      { id: 83, value: 'PORTORRIQUENHO' },
      { id: 84, value: 'PORTUGUÊS' },
      { id: 85, value: 'QATARENSE' },
      { id: 86, value: 'QUENIANO' },
      { id: 87, value: 'ROMENO' },
      { id: 88, value: 'RUANDÊS' },
      { id: 89, value: 'RUSSO' },
      { id: 90, value: 'SALVADORENHO' },
      { id: 91, value: 'SANTA-LUCENSE' },
      { id: 92, value: 'SÃO-CRISTOVENSE' },
      { id: 93, value: 'SÃO-VICENTINO' },
      { id: 94, value: 'SAUDITA' },
      { id: 95, value: 'SÉRVIO' },
      { id: 96, value: 'SÍRIO' },
      { id: 97, value: 'SOMALI' },
      { id: 98, value: 'SUECO' },
      { id: 99, value: 'SUÍÇO' },
      { id: 100, value: 'SUL-AFRICANO' },
      { id: 101, value: 'SUL-COREANO, COREANO' },
      { id: 102, value: 'SURINAMÊS' },
      { id: 103, value: 'TAILANDÊS' },
      { id: 104, value: 'TRINDADENSE' },
      { id: 105, value: 'TURCO' },
      { id: 106, value: 'UCRANIANO' },
      { id: 107, value: 'UGANDENSE' },
      { id: 108, value: 'URUGUAIO' },
      { id: 109, value: 'VENEZUELANO' },
      { id: 110, value: 'VIETNAMITA' },
      { id: 111, value: 'ZIMBABUENSE' },
    ];
  
  export const cores = [
      { id: 0, value: '' },
      { id: 1, value: 'BRANCA' },
      { id: 2, value: 'NÃO DECLARADA' },
      { id: 3, value: 'PARDA' },
      { id: 4, value: 'PRETA' },
      { id: 5, value: 'AMARELA' },
    ];
  
  export const generos   = [
      { id: 0, value: '' },
      { id: 1, value: 'FEMININO' },
      { id: 2, value: 'MASCULINO' },
    ];
  
    export const pne   = [
      { id: 0, value: 'NÃO' },
      { id: 1, value: 'SIM' },
    ];
  
  
  
  export const estadoCivil = [
      { id: 1, value: 'CASADO' },
      { id: 2, value: 'DIVORCIADO' },
      { id: 3, value: 'SEPARADO JUDICIALMENTE' },
      { id: 4, value: 'SOLTEIRO' },
      { id: 5, value: 'VIÚVO' },
    ];
  
  export const escolaridades = [
    { id: 1, description: '5º ANO COMPLETO DO ENSINO FUNDAMENTAL' },
  { id: 2, description: 'ANALFABETO, INCLUSIVE O QUE, EMBORA TENHA RECEBIDO INSTRUÇÃO, NÃO SE ALFABETIZOU' },
  { id: 3, description: 'ATÉ O 5º ANO INCOMPLETO DO ENSINO' },
  { id: 4, description: 'ATÉ O 5º ANO INCOMPLETO DO ENSINO FUNDAMENTAL (ANTIGA ' },
  { id: 5, description: 'ATÉ O 5º ANO INCOMPLETO DO ENSINO FUNDAMENTAL (ANTIGA 4ª SÉRIE) QUE SE TENHA ALFABETIZADO SEM TER FREQUENTADO ESCOLA REGULAR' },
  { id: 6, description: 'ATÉ O 5º ANO INCOMPLETO DO ENSINO FUNDAMENTAL (ANTIGA 4ª SÉRIE) QUE SE TENHA ALFABETIZADO SEM TER FREQUENTADO ESCOLA REGULAR' },
  { id: 7, description: 'COMPLETA' },
  { id: 8, description: 'DO 6º AO 9º ANO DO ENSINO FUNDAMENTAL' },
  { id: 9, description: 'DO 6º AO 9º ANO DO ENSINO FUNDAMENTAL INCOMPLETO (ANTIGA 5ª' },
  { id: 10, description: 'DO 6º AO 9º ANO DO ENSINO FUNDAMENTAL INCOMPLETO (ANTIGA 5ª 8ª SÉRIE)' },
  { id: 11, description: 'EDUCAÇÃO SUPERIOR' },
  { id: 12, description: 'EDUCAÇÃO SUPERIOR COMPLETA' },
  { id: 13, description: 'EDUCAÇÃO SUPERIOR INCOMPLETA' },
  { id: 14, description: 'ENSINO FUNDAMENTAL' },
  { id: 15, description: 'ENSINO FUNDAMENTAL COMPLETO' },
  { id: 16, description: 'ENSINO MÉDIO' },
  { id: 17, description: 'ENSINO MÉDIO COMPLETO' },
  { id: 18, description: 'ENSINO MÉDIO INCOMPLETO' },
  { id: 19, description: 'MESTRADO COMPLETO' },
  { id: 20, description: 'PÓS-GRADUAÇÃO' },
    ];
  
  export const orgaoEmissorRg =  [
    { id: 1, name: 'CONSELHO FEDERAL DE MEDICINA VETERINÁRIA', acronym: 'CFMV' },
    { id: 2, name: 'CONSELHO FEDERAL DE NUTRIÇÃO', acronym: 'CFN' },
    { id: 3, name: 'CONSELHO FEDERAL DE ODONTOLOGIA', acronym: 'CFO' },
    { id: 4, name: 'CONSELHO FEDERAL DE PSICOLOGIA', acronym: 'CFP' },
    { id: 5, name: 'CONSELHO REGIONAL DE QUÍMICA', acronym: 'CFQ' },
    { id: 6, name: 'CONSELHO FEDERAL DOS TÉCNICOS INDUSTRIAIS', acronym: 'CFT' },
    { id: 7, name: 'CONSELHO FEDERAL DOS TÉCNICOS AGRÍCOLAS', acronym: 'CFTA' },
    { id: 8, name: 'COORDENAÇÃO GERAL DE PRIVILÉGIOS E IMUNIDADES', acronym: 'CGPI' },
    { id: 9, name: 'COORDENADORIA GERAL DE POLÍCIA MARÍTIMA, AERONÁUTICA E DE FRONTEIRAS', acronym: 'CGPMAF' },
    { id: 10, name: 'CENTRO DE INTELIGÊNCIA DA POLÍCIA CIVIL', acronym: 'CIPC' },
    { id: 11, name: 'CONSELHO NACIONAL DE IMIGRAÇÃO', acronym: 'CNIG' },
    { id: 12, name: 'CONFEDERAÇÃO NACIONAL DO TRANSPORTE', acronym: 'CNT' },
    { id: 13, name: 'CONFEDERAÇÃO NACIONAL DE VIGILANTES & PRESTADORES DE SERVIÇOS', acronym: 'CNTV' },
    { id: 14, name: 'CONSELHO FEDERAL DE CORRETORES DE IMÓVEIS', acronym: 'COFECI' },
    { id: 15, name: 'CONSELHO FEDERAL DE ECONOMIA', acronym: 'COFECON' },
    { id: 16, name: 'CONSELHO FEDERAL DE MUSEOLOGIA', acronym: 'COFEM' },
    { id: 17, name: 'CONSELHO FEDERAL DE ENFERMAGEM', acronym: 'COFEN' },
    { id: 18, name: 'CONSELHO REGIONAL DE FISIOTERAPIA E TERAPIA OCUPACIONAL', acronym: 'COFFITO' },
    { id: 19, name: 'COMANDO DA AERONÁUTICA', acronym: 'COMAER' },
    { id: 20, name: 'CONSELHO FEDERAL DE ESTATÍSTICA', acronym: 'CONFE' },
    { id: 21, name: 'CONSELHO FEDERAL DE ENGENHARIA E AGRONOMIA', acronym: 'CONFEA' },
    { id: 22, name: 'CONSELHO FEDERAL DE EDUCAÇÃO FÍSICA', acronym: 'CONFEF' },
    { id: 23, name: 'CONSELHO FEDERAL DOS REPRESENTANTES COMERCIAIS', acronym: 'CONFERE' },
    { id: 24, name: 'CONSELHO REGIONAL DE ESTATÍSTICA', acronym: 'CONRE' },
    { id: 25, name: 'CONSELHO FEDERAL DE PROFISSIONAIS DE RELAÇÕES PÚBLICAS', acronym: 'CONRERP' },
    { id: 26, name: 'CONSELHO REGIONAL DOS REPRESENTANTES COMERCIAIS', acronym: 'CORE' },
    { id: 27, name: 'CONSELHO REGIONAL DE ECONOMIA', acronym: 'CORECON' },
    { id: 28, name: 'CONSELHO REGIONAL DE MUSEOLOGIA', acronym: 'COREM' },
    { id: 29, name: 'CONSELHO REGIONAL DE ENFERMAGEM', acronym: 'COREN' },
    { id: 30, name: 'CONSELHO REGIONAL DE ADMINISTRAÇÃO', acronym: 'CRA' },
    { id: 31, name: 'CENTRO DE REFERÊNCIA DE ASSISTÊNCIA SOCIAL', acronym: 'CRAS' },
    { id: 32, name: 'CONSELHO REGIONAL DE BIBLIOTECONOMIA', acronym: 'CRB' },
    { id: 33, name: 'CONSELHO REGIONAL DE BIOLOGIA', acronym: 'CRBIO' },
    { id: 34, name: 'CONSELHO REGIONAL DE BIOMEDICINA', acronym: 'CRBM' },
    { id: 35, name: 'CONSELHO REGIONAL DE CONTABILIDADE', acronym: 'CRC' },
    { id: 36, name: 'CONSELHO REGIONAL DE ENGENHARIA E AGRONOMIA', acronym: 'CREA' },
    { id: 37, name: 'CONSELHO REGIONAL DE CORRETORES DE IMÓVEIS', acronym: 'CRECI' },
    { id: 38, name: 'CONSELHO REGIONAL DE EDUCAÇÃO FÍSICA', acronym: 'CREF' },
    { id: 39, name: 'CONSELHO REGIONAL DE FISIOTERAPIA E TERAPIA OCUPACIONAL', acronym: 'CREFITO' },
    { id: 40, name: 'CONSELHO REGIONAL DE SERVIÇO SOCIAL', acronym: 'CRESS' },
    { id: 41, name: 'CONSELHO REGIONAL DE FARMÁCIA', acronym: 'CRF' },
    { id: 42, name: 'CONSELHO REGIONAL DE FONOAUDIOLOGIA', acronym: 'CRFA' },
    { id: 43, name: 'CONSELHO REGIONAL DE MEDICINA', acronym: 'CRM' },
    { id: 44, name: 'CONSELHO REGIONAL DE MEDICINA VETERINÁRIA', acronym: 'CRMV' },
    { id: 45, name: 'CONSELHO REGIONAL DE NUTRIÇÃO', acronym: 'CRN' },
    { id: 46, name: 'CONSELHO REGIONAL DE ODONTOLOGIA', acronym: 'CRO' },
    { id: 47, name: 'CONSELHO REGIONAL DE PSICOLOGIA', acronym: 'CRP' },
    { id: 48, name: 'CONSELHO REGIONAL DE PROFISSIONAIS DE RELAÇÕES PÚBLICAS', acronym: 'CRPRE' },
    { id: 49, name: 'CONSELHO REGIONAL DE QUÍMICA', acronym: 'CRQ' },
    { id: 50, name: 'CONSELHO REGIONAL DOS TÉCNICOS INDUSTRIAIS', acronym: 'CRT' },
    { id: 51, name: 'CONSELHO REGIONAL DE TÉCNICOS DE ADMINISTRAÇÃO', acronym: 'CRTA' },
    { id: 52, name: 'CARTEIRA DE TRABALHO E PREVIDÊNCIA SOCIAL', acronym: 'CTPS' },
    { id: 53, name: 'CARTÓRIO CIVIL', acronym: 'CV' },
    { id: 54, name: 'DELEGACIA DE POLÍCIA DE IMIGRAÇÃO', acronym: 'DELEMIG' },
    { id: 55, name: 'DEPARTAMENTO ESTADUAL DE TRÂNSITO', acronym: 'DETRAN' },
    { id: 56, name: 'DIRETORIA GERAL DA POLÍCIA CIVIL', acronym: 'DGPC' },
    { id: 57, name: 'DIRETORIA DE IDENTIFICAÇÃO CIVIL', acronym: 'DIC' },
    { id: 58, name: 'DIRETORIA DE IDENTIFICAÇÃO CIVIL E CRIMINAL', acronym: 'DICC' },
    { id: 59, name: 'DIRETORIA EXECUTIVA', acronym: 'DIREX' },
    { id: 60, name: 'DEPARTAMENTO DE POLÍCIA FEDERAL', acronym: 'DPF' },
    { id: 61, name: 'DIVISÃO DE POLÍCIA MARÍTIMA, AÉREA E DE FRONTEIRAS', acronym: 'DPMAF' },
    { id: 62, name: 'DEPARTAMENTO DE POLÍCIA TÉCNICA GERAL', acronym: 'DPT' },
    { id: 63, name: 'DEPARTAMENTO DE POLÍCIA TÉCNICO CIENTÍFICA', acronym: 'DPTC' },
    { id: 64, name: 'DELEGACIA REGIONAL EXECUTIVA', acronym: 'DREX' },
    { id: 65, name: 'DELEGACIA REGIONAL DO TRABALHO', acronym: 'DRT' },
    { id: 66, name: 'EXÉRCITO BRASILEIRO', acronym: 'EB' },
    { id: 67, name: 'FORÇA AÉREA BRASILEIRA', acronym: 'FAB' },
    { id: 68, name: 'FEDERAÇÃO NACIONAL DOS JORNALISTAS', acronym: 'FENAJ' },
    { id: 69, name: 'FUNDO DE GARANTIA DO TEMPO DE SERVIÇO', acronym: 'FGTS' },
    { id: 70, name: 'FUNDAÇÃO INSTITUTO DE PESQUISAS ECONÔMICAS', acronym: 'FIPE' },
    { id: 71, name: 'FUNDAÇÃO LYNDOLPHO SILVA', acronym: 'FLS' },
    { id: 72, name: 'FUNDAÇÃO NACIONAL DO ÍNDIO', acronym: 'FUNAI' },
    { id: 73, name: 'GERÊNCIA DE ESTADO DE JUSTIÇA, SEGURANÇA PÚBLICA E CIDADANIA', acronym: 'GEJSP' },
    { id: 74, name: 'GERÊNCIA DE ESTADO DE JUSTIÇA, SEGURANÇA PÚBLICA E CIDADANIA', acronym: 'GEJSPC' },
    { id: 75, name: 'GERÊNCIA DE ESTADO DE JUSTIÇA, SEGURANÇA PÚBLICA E CIDADANIA', acronym: 'GEJUSPC' },
    { id: 76, name: 'GERÊNCIA DE ESTADO DE SEGURANÇA PÚBLICA', acronym: 'GESP' },
    { id: 77, name: 'GOVERNO DO ESTADO DE GOIÁS', acronym: 'GOVGO' },
    { id: 78, name: 'CARTEIRA DE IDENTIDADE CLASSISTA', acronym: 'I CLA' },
    { id: 79, name: 'INSTITUTO DE POLÍCIA CIENTÍFICA', acronym: 'ICP' },
    { id: 80, name: 'INSTITUTO DE IDENTIFICAÇÃO DR. AROLDO MENDES PAIVA', acronym: 'IDAMP' },
    { id: 81, name: 'INSTITUTO FÉLIX PACHECO', acronym: 'IFP' },
    { id: 82, name: 'INSTITUTO GERAL DE PERÍCIAS', acronym: 'IGP' },
    { id: 83, name: 'INSTITUTO DE IDENTIFICAÇÃO ADERSON CONCEIÇÃO DE MELO', acronym: 'IIACM' },
    { id: 84, name: 'INSTITUTO DE IDENTIFICAÇÃO CIVIL E CRIMINAL', acronym: 'IICC' },
    { id: 85, name: 'INSTITUTO DE IDENTIFICAÇÃO CIVIL E CRIMINAL ENGRÁCIA DA COSTA FRANCISCO', acronym: 'IICCECF' },
    { id: 86, name: 'INSTITUTO DE IDENTIFICAÇÃO CARLOS MENEZES', acronym: 'IICM' },
    { id: 87, name: 'INSTITUTO DE IDENTIFICAÇÃO GONÇALO PEREIRA', acronym: 'IIGP' },
    { id: 88, name: 'INSTITUTO DE IDENTIFICAÇÃO JOÃO DE DEUS MARTINS', acronym: 'IIJDM' },
    { id: 89, name: 'INSTITUTO DE IDENTIFICAÇÃO DA POLÍCIA CIVIL', acronym: 'IIPC' },
    { id: 90, name: 'INSTITUTO DE IDENTIFICAÇÃO PEDRO MELLO', acronym: 'IIPC' },
    { id: 91, name: 'INSTITUTO DE IDENTIFICAÇÃO RICARDO GUMBLETON DAUNT', acronym: 'IIRGD' },
    { id: 92, name: 'INSTITUTO DE IDENTIFICAÇÃO RAIMUNDO HERMÍNIO DE MELO', acronym: 'IIRHM' },
    { id: 93, name: 'INSTITUTO DE IDENTIFICAÇÃO TAVARES BURIL', acronym: 'IITB' },
  { id: 94, name: 'INSTITUTO MÉDICO-LEGAL', acronym: 'IML' },
  { id: 95, name: 'INSTITUTO NACIONAL DE IDENTIFICAÇÃO', acronym: 'INI' },
  { id: 96, name: 'INSTITUTO PEREIRA FAUSTINO', acronym: 'IPF' },
  { id: 97, name: 'INSTITUTO TÉCNICO-CIENTÍFICO DE PERÍCIA', acronym: 'ITCP' },
  { id: 98, name: 'INSTITUTO TÉCNICO-CIENTÍFICO DE PERÍCIA', acronym: 'ITEP' },
  { id: 99, name: 'MINISTÉRIO DA AERONÁUTICA', acronym: 'MAER' },
  { id: 100, name: 'MARINHA DO BRASIL', acronym: 'MB' },
  { id: 101, name: 'MINISTÉRIO DA DEFESA', acronym: 'MD' },
  { id: 102, name: 'MINISTÉRIO DA CIDADANIA', acronym: 'MDS' },
  { id: 103, name: 'MINISTÉRIO DA EDUCAÇÃO E CULTURA', acronym: 'MEC' },
  { id: 104, name: 'MINISTÉRIO DO EXÉRCITO', acronym: 'MEX' },
  { id: 105, name: 'MINISTÉRIO DA DEFESA', acronym: 'MINDEF' },
  { id: 106, name: 'MINISTÉRIO DA JUSTIÇA', acronym: 'MJ' },
  { id: 107, name: 'MINISTÉRIO DA MARINHA', acronym: 'MM' },
  { id: 108, name: 'MINISTÉRIO DA MARINHA', acronym: 'MMA' },
  { id: 109, name: 'MINISTÉRIO DA PREVIDÊNCIA E ASSISTÊNCIA SOCIAL', acronym: 'MPAS' },
  { id: 110, name: 'MINISTÉRIO PÚBLICO ESTADUAL', acronym: 'MPE' },
  { id: 111, name: 'MINISTÉRIO PÚBLICO FEDERAL', acronym: 'MPF' },
  { id: 112, name: 'MINISTÉRIO PÚBLICO DO TRABALHO', acronym: 'MPT' },
  { id: 113, name: 'MINISTÉRIO DAS RELAÇÕES EXTERIORES', acronym: 'MRE' },
  { id: 114, name: 'MINISTÉRIO DO TRABALHO', acronym: 'MT' },
  { id: 115, name: 'MINISTÉRIO DA ECONOMIA', acronym: 'MTE' },
  { id: 116, name: 'MINISTÉRIO DO TRABALHO E PREVIDÊNCIA SOCIAL', acronym: 'MTPS' },
  { id: 117, name: 'NÚCLEO DE POLÍCIA DE IMIGRAÇÃO', acronym: 'NUMIG' },
  { id: 118, name: 'ORDEM DOS ADVOGADOS DO BRASIL', acronym: 'OAB' },
  { id: 119, name: 'ORDENS DOS MÚSICOS DO BRASIL', acronym: 'OMB' },
  { id: 120, name: 'POLÍCIA CIVIL', acronym: 'PC' },
  { id: 121, name: 'POLÍCIA FEDERAL', acronym: 'PF' },
  { id: 122, name: 'PROCURADORIA GERAL DA FAZENDA NACIONAL', acronym: 'PGFN' },
  { id: 123, name: 'POLÍCIA MILITAR', acronym: 'PM' },
  { id: 124, name: 'PERÍCIA OFICIAL E IDENTIFICAÇÃO TÉCNICA', acronym: 'POLITEC' },
  { id: 125, name: 'POLÍCIA RODOVIÁRIA FEDERAL', acronym: 'PRF' },
  { id: 126, name: 'POLÍCIA TECNICO-CIENTÍFICA', acronym: 'PTC' },
  { id: 127, name: 'SECRETARIA DE ESTADO DA CASA CIVIL', acronym: 'SCC' },
  { id: 128, name: 'SECRETARIA COORDENADORA DE JUSTIÇA E DEFESA SOCIAL', acronym: 'SCJDS' },
  { id: 129, name: 'SECRETARIA DE DEFESA SOCIAL', acronym: 'SDS' },
  { id: 130, name: 'SECRETARIA DE ESTADO DA CASA CIVIL', acronym: 'SECC' },
  { id: 131, name: 'SECRETARIA DE ESTADO DA CASA CIVIL E DESENVOLVIMENTO ECONÔMICO', acronym: 'SECCDE' },
  { id: 132, name: 'SECRETARIA DE ESTADO DA DEFESA SOCIAL', acronym: 'SEDS' },
  { id: 133, name: 'SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E DA DEFESA SOCIAL', acronym: 'SEGUP' },
  { id: 134, name: 'SECRETARIA DE ESTADO DE JUSTIÇA E SEGURANÇA PÚBLICA', acronym: 'SEJSP' },
  { id: 135, name: 'SECRETARIA DE ESTADO DA JUSTICA', acronym: 'SEJUC' },
  { id: 136, name: 'SECRETARIA DE ESTADO DE JUSTIÇA E SEGURANÇA PÚBLICA', acronym: 'SEJUSP' },
  { id: 137, name: 'SECRETARIA DE ESTADO DA POLÍCIA CIVIL', acronym: 'SEPC' },
  { id: 138, name: 'SECRETARIA DE ESTADO DA SEGURANÇA', acronym: 'SES' },
  { id: 139, name: 'SECRETARIA DE ESTADO DA SEGURANÇA E CIDADANIA', acronym: 'SESC' },
  { id: 140, name: 'SECRETARIA DE ESTADO DA SEGURANÇA, DEFESA E CIDADANIA', acronym: 'SESDC' },
  { id: 141, name: 'SECRETARIA DE ESTADO DA SEGURANÇA, DEFESA E CIDADANIA', acronym: 'SESDEC' },
  { id: 142, name: 'SECRETARIA ESTADUAL DE SEGURANÇA', acronym: 'SESEG' },
  { id: 143, name: 'SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA', acronym: 'SESP' },
  { id: 144, name: 'SECRETARIA DE ESTADO DA SEGURANÇA PÚBLICA E ADMINISTRAÇÃO PENITENCIÁRIA', acronym: 'SESPAP' },
  { id: 145, name: 'SECRETARIA DE ESTADO DE SEGURANÇA PUBLICA E DEFESA DO CIDADÃO', acronym: 'SESPDC' },
  { id: 146, name: 'SECRETARIA DE ESTADO DE SEGURANÇA PÚBLICA E DEFESA SOCIAL', acronym: 'SESPDS' },
  { id: 147, name: 'SUPERINTENDÊNCIA GERAL DE POLÍCIA CIVIL', acronym: 'SGPC' },
  { id: 148, name: 'SUPERINTENDÊNCIA GERAL DE POLÍCIA JUDICIÁRIA', acronym: 'SGPJ' },
  { id: 149, name: 'SERVIÇO DE IDENTIFICAÇÃO DA MARINHA', acronym: 'SIM' },
  { id: 150, name: 'SECRETARIA DA JUSTIÇA', acronym: 'SJ' },
  { id: 151, name: 'SECRETARIA DA JUSTIÇA E DOS DIREITOS HUMANOS', acronym: 'SJCDH' },
  { id: 152, name: 'SECRETARIA COORDENADORA DE JUSTIÇA E DEFESA SOCIAL', acronym: 'SJDS' },
  { id: 153, name: 'SECRETARIA DA JUSTIÇA E SEGURANÇA', acronym: 'SJS' },
  { id: 154, name: 'SECRETARIA DA JUSTIÇA DO TRABALHO E CIDADANIA', acronym: 'SJTC' },
  { id: 155, name: 'SECRETARIA DA JUSTIÇA DO TRABALHO E SEGURANÇA', acronym: 'SJTS' },
  { id: 156, name: 'SECRETARIA NACIONAL DE JUSTIÇA / DEPARTAMENTO DE ESTRANGEIROS', acronym: 'SNJ' },
  { id: 157, name: 'SERVIÇO DE POLÍCIA MARÍTIMA, AÉREA E DE FRONTEIRAS', acronym: 'SPMAF' },
  { id: 158, name: 'SECRETARIA DE POLÍCIA TÉCNICO-CIENTÍFICA', acronym: 'SPTC' },
  { id: 159, name: 'SUPERINTENDÊNCIA REGIONAL DO DEPARTAMENTO DE POLÍCIA FEDERAL', acronym: 'SRDPF' },
  { id: 160, name: 'RECEITA FEDERAL', acronym: 'SRF' },
  { id: 161, name: 'SUPERINTENDÊNCIA REGIONAL DO TRABALHO', acronym: 'SRTE' },
  { id: 162, name: 'SECRETARIA DA SEGURANÇA, DEFESA E CIDADANIA', acronym: 'SSDC' },
  { id: 163, name: 'SECRETARIA DA SEGURANÇA E DA DEFESA SOCIAL', acronym: 'SSDS' },
  { id: 164, name: 'SECRETARIA DE SEGURANÇA E INFORMAÇÕES', acronym: 'SSI' },
  { id: 165, name: 'SECRETARIA DE SEGURANÇA PÚBLICA', acronym: 'SSP' },
  { id: 166, name: 'SECRETARIA DE SEGURANÇA PÚBLICA E COORDENADORIA GERAL DE PERÍCIAS', acronym: 'SSPCGP' },
  { id: 167, name: 'SECRETARIA DE SEGURANÇA PÚBLICA E DEFESA DO CIDADÃO', acronym: 'SSPDC' },
  { id: 168, name: 'SECRETARIA DE SEGURANÇA PÚBLICA E DEFESA SOCIAL', acronym: 'SSPDS' },
  { id: 169, name: 'SECRETARIA DE SEGURANÇA PÚBLICA POLÍCIA CIVIL', acronym: 'SSPPC' },
  { id: 170, name: 'SUPERINTENDÊNCIA DE SEGUROS PRIVADOS', acronym: 'SUSEP' },
  { id: 171, name: 'SUPERINTENDÊNCIA DOS SERVIÇOS PENITENCIÁRIOS', acronym: 'SUSEPE' },
  { id: 172, name: 'TRIBUNAL DE JUSTIÇA', acronym: 'TJ' },
  { id: 173, name: 'TRIBUNAL ARBITRAL E MEDIAÇÃO DOS ESTADOS BRASILEIROS', acronym: 'TJAEM' },
  { id: 174, name: 'TRIBUNAL REGIONAL ELEITORAL', acronym: 'TRE' },
  { id: 175, name: 'TRIBUNAL REGIONAL FEDERAL', acronym: 'TRF' },
  { id: 176, name: 'TRIBUNAL SUPERIOR ELEITORAL', acronym: 'TSE' },
  { id: 177, name: 'ORGÃO ESTRANGEIRO', acronym: 'XXX' },
  { id: 178, name: 'OUTRO', acronym: 'ZZZ' },
    ]
  
  export const tiposDeficiencias = [
      { id: 1, description: 'AUDITIVA' },
      { id: 2, description: 'FÍSICA' },
      { id: 3, description: 'INTELECTUAL' },
      { id: 4, description: 'MÚLTIPLA' },
      { id: 5, description: 'PSICOSSOCIAL' },
      { id: 6, description: 'VISUAL' },
      { id: 7, description: 'OUTROS' },
    ];
  
  export const escolhaBinaria = [
      { value: '', label: '' },
      { value: false, label: 'NÃO' },
      { value: true, label: 'SIM' },
    ];