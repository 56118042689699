import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Carousel from 'react-bootstrap/Carousel';
import './style.css'


// Estilos customizados
const useStyles = makeStyles(() => ({
    dialogTitle: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '1rem',
    },
    dialogContent: {
        textAlign: 'center',
        fontSize: '1.1rem',
        color: '#666',
        padding: '20px',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: '16px',
    },
    closeButton: {
        backgroundColor: '#1976d2',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#1565c0',
        },
        padding: '8px 24px',
        fontSize: '1rem',
        textTransform: 'none',
        borderRadius: '20px',
    },
}));

const PopUpDialog = () => {
    const imageUrl = `${process.env.PUBLIC_URL}/assets/images/fundo/fundo-branco.png`;
    const popUps = JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).user.listaAvisos;

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const isDialogDismissed = localStorage.getItem('popupDialogDismissed');
        if (!isDialogDismissed && popUps && popUps.length > 0) {
            setOpen(true);
        }
    }, []);

    const handleClose = () => {
        localStorage.setItem('popupDialogDismissed', true);
        setOpen(false);
    };

    const getPopup = (popup) => {
        return (
            <Carousel.Item>
                {popup.tipo == 1 ? <img height="400px" src={imageUrl} alt="Slide" /> :
                    popup.tipo == 2 ? <img height="400px" src={"data:image/jpeg;base64," + popup.arquivo} alt="Slide" /> :
                        popup.item == 3 ? <video
                            src={"data:video/mp4;base64," + popup.arquivo}
                            controls
                            width="350"
                            style={{ display: "block", marginTop: "10px" }}>
                        </video> : <></>}
                <Carousel.Caption style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '65vh', height: '100%', }}>
                    {
                        popup.tipo == 1 ?
                            <p style={{ color: 'black', paddingLeft: '50px', paddingRight: '50px' }}>{popup.texto}</p> : <></>
                    }

                </Carousel.Caption>
            </Carousel.Item>
        )
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{ style: { borderRadius: '12px', padding: '10px' } }}
        >
            <DialogTitle className={classes.dialogTitle}>Avisos</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Carousel activeIndex={index} onSelect={handleSelect} style={{ width: '500px' }}>
                    {
                        popUps?.map((popup) => {
                            return getPopup(popup);
                        })
                    }
                </Carousel>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={handleClose} className={classes.closeButton}>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PopUpDialog;
