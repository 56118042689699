import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";

function createData(dataEvento, titulo, descricao, usuario) {
  return { dataEvento, titulo, descricao, usuario };
}

const rows = [
  createData("11/12/2024", "TÍTULO", "DESCRIÇÃO", "USUÁRIO"),
  createData("11/12/2024", "TÍTULO", "DESCRIÇÃO", "USUÁRIO"),
  createData("11/12/2024", "TÍTULO", "DESCRIÇÃO", "USUÁRIO"),
  createData("11/12/2024", "TÍTULO", "DESCRIÇÃO", "USUÁRIO"),
  createData("11/12/2024", "TÍTULO", "DESCRIÇÃO", "USUÁRIO"),
];

const Historico = () => {
  return (
    <div
      className="tab-pane fade show active"
      id="historico"
      role="tabpanel"
      aria-labelledby="historico-tab"
    >
      <div className="card-body">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Data Evento</TableCell>
                <TableCell align="center">Título</TableCell>
                <TableCell align="center">Descrição</TableCell>
                <TableCell align="center">Usuário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.name}>
                  <TableCell align="center">{row.dataEvento}</TableCell>
                  <TableCell align="center">{row.titulo}</TableCell>
                  <TableCell align="center">{row.descricao}</TableCell>
                  <TableCell align="center">{row.usuario}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Registros por página"
          component="div"
          count={rows.length}
          rowsPerPage={5}
          page={1}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
        />
      </div>
    </div>
  );
};
export default Historico;
