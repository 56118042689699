import { useMemo, useEffect, useState } from 'react'
import Breadcrumb from '../../components/breadcrumb';
import Table from '../../components/table';
import BaseConfiguracoes from '../configuracoes/baseConfiguracoes';
import urlsConfiguracoes from '../configuracoes/urlsConfiguracoes';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery, useMutation } from 'react-query'
import { toast } from 'react-toastify';
import { getPopups, deletePopup } from './../../services/popups'
import PopUpDialogPreview from './dialog/popupDialogPreview';
import PopUpDialogUnidadesCargos from './dialog/popupDialogUnidadesCargos';

const PopUpPage = () => {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [total, setTotal] = useState()
  const [open, setOpen] = useState(false);
  const [openUnidadesCargos, setOpenUnidadesCargos] = useState(false);
  const [listaUnidadesCargos, setListaOpenUnidadesCargos] = useState([]);
  const [unidadesCargos, setUnidadesCargos] = useState('');
  const [conteudoSelecionado, setConteudoSelecionado] = useState('');
  const [tipoSelecionado, setTipoSelecionado] = useState('');

  const { isLoading: loading, refetch } = useQuery('getPopUps', () => getPopups(skip, limit), {
    onSuccess: (data) => {
      data.data.forEach(element => {
        element.conteudo = element.tipo == 1 ? element.descricao : element.arquivo;
        element.tipoDesc = element.tipo == 1 ? 'Texto' : element.tipo == 2 ? "Imagem" : "Vídeo";
      });

      setData(data.data);
      setTotal(data.total);
      setLimit(data.limit);
      setSkip(data.skip);
    },
    enabled: false,
    retry: true
  })


  useEffect(() => {
    refetch();
  }, [limit, skip]);

  


  const { mutate: deleteMutate, isLoading: loadingDelete } = useMutation(deletePopup, {
    onSuccess: () => {
      toast.success('Popup excluído com sucesso!');
      refetch();
    },
    onError: () => {
      toast.error('Algo deu errado. Tente novamente mais tarde.');
    }
  })


  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Tipo', accessor: 'tipoDesc' },
      {
        Header: 'Unidades', accessor: '',
        Cell: ({ row }) => (
          <button onClick={() => {
            setListaOpenUnidadesCargos(row.original.unidades);
            setUnidadesCargos('Unidades')
            setOpenUnidadesCargos(true)
          }
          }
            className="btn btn-sm btn-primary"  >
            <i className="material-icons md-visibility"></i> Unidades
          </button>
        )
      },
      {
        Header: 'Cargos', accessor: '',
        Cell: ({ row }) => (
          <button onClick={() => {
            setListaOpenUnidadesCargos(row.original.cargos);
            setUnidadesCargos('Cargos')
            setOpenUnidadesCargos(true)
          }
          }
            className="btn btn-sm btn-primary"  >
            <i className="material-icons md-visibility"></i> Cargos
          </button>
        )
      },
      {
        Header: 'Conteúdo', accessor: '',
        Cell: ({ row }) => (
          <button onClick={() => {
            setConteudoSelecionado(row.original.conteudo);
            setTipoSelecionado(row.original.tipo)
            setOpen(true)
          }
          }
            className="btn btn-sm btn-primary"  >
            <i className="material-icons md-visibility"></i> Conteúdo
          </button>
        )
      },
      {
        Header: 'Ações',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>
           <button onClick={() => navigate(urlsConfiguracoes.popupEditar + row.original.id, {
              state: {
                popup: row.original
              }
            })} className="btn btn-sm btn-primary"
            >
              <i className="material-icons md-edit_note"></i> editar
            </button>
            <button
              style={{ marginLeft: '8px' }}
              onClick={() => {
                const confirm = window.confirm('Tem certeza que deseja excluir?')
                if (confirm) {
                  deleteMutate(row.original.id)
                }
              }}
              className="btn btn-sm btn-danger"
            >
              <i className="material-icons md-group_remove"></i> excluir
            </button>
          </>
        )
      }
    ],
    [navigate, data]
  )

  return (
    <BaseConfiguracoes>
      <PopUpDialogPreview open={open} setOpen={setOpen} conteudo={conteudoSelecionado} tipo={tipoSelecionado}></PopUpDialogPreview>
      <PopUpDialogUnidadesCargos open={openUnidadesCargos} setOpen={setOpenUnidadesCargos} conteudo={listaUnidadesCargos} tipo={unidadesCargos}></PopUpDialogUnidadesCargos>
      <Breadcrumb
        title={'Popups'}
        itens={['Gestão', 'Secretaria', 'Popups', 'Lista Geral']}
        actions={{
          link: urlsConfiguracoes.popupCadastrar,
          text: 'Novo Popup'
        }}
      />
      <div className="row">
        <div className="col-12">
          <div className="card">
            <Table
              columns={columns}
              data={data}
              hasPagination
              limit={limit}
              setLimit={setLimit}
              skip={skip}
              setSkip={setSkip}
              totalItems={total}
            />
          </div>
        </div>
      </div>
    </BaseConfiguracoes>
  )
}

export default PopUpPage
