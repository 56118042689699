import { useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "react-query";
import Breadcrumb from "../../../components/breadcrumb";
import BaseGestao from "../../gestao/baseGestao";
import BaseGestaoUnidades from "../../gestao-unidades/baseGestaoUnidades";
import urlsGestao from "../../gestao/urlsGestao";
import urlsGestaoUnidades from "../../gestao-unidades/urlsGestaoUnidades";
import * as S from "./styles";
import {
  updateQuadro,
  deleteQuadro,
  getQuadro,
} from "../../../services/quadro-horario";
import QuadroDeHorarioAbaDia from "./tab";
import { getUnidade } from "../../../services/unidades";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const QuadroDeHorarioDisciplinas = (gestao) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [canEdit, setCanEdit] = useState(true);
  const [responseData, setResponseData] = useState([]);
  const [schedule, setSchedule] = useState();
  const [requestData, setRequestData] = useState();
  const [nomeUnidade, setNomeUnidade] = useState("");
  const { state } = useLocation();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const homeQuadro = gestao.gestao
    ? urlsGestao.quadroHorario
    : urlsGestaoUnidades.quadroHorario;
  const alocarDisciplinaQuadro = gestao.gestao
    ? urlsGestao.quadroHorarioAlocarDisciplina
    : urlsGestaoUnidades.quadroHorarioAlocarDisciplina;
  const impressaoQuadroHorario = gestao.gestao
    ? urlsGestao.quadroHorarioImpressao
    : urlsGestaoUnidades.quadroHorarioImpressao;
  const BaseComponent = gestao.gestao ? BaseGestao : BaseGestaoUnidades;
  const BasePath = gestao.gestao ? "Gestão" : "Gestão Unidades";

  const {
    isError: errorGet,
    isLoading: loadingGet,
    refetch: getRefetch,
    data: quadroData,
  } = useQuery("getQuadro", () => getQuadro(id), {
    retry: true,
    enabled: true,
    onSuccess: (data) => {
      setResponseData(data);
    },
    onError: () => {
      toast.error("Algo deu errado, tente novamente.");
    },
  });

  const {
    isError: errorDelete,
    isLoading: loadingDelete,
    refetch: deleteRefetch,
  } = useQuery("deleteQuadro", () => deleteQuadro(id), {
    retry: 0,
    enabled: false,
    onSuccess: () => {
      toast.success("Quadro de horários excluído com sucesso!");
      navigate(homeQuadro);
    },
    onError: () => {
      toast.error("Algo deu errado, tente novamente.");
    },
  });

  const {
    isError: errorUpdate,
    isLoading: loadingUpdate,
    refetch: updateRefetch,
  } = useQuery("updateQuadro", () => updateQuadro(id, requestData), {
    retry: 0,
    enabled: false,
    onSuccess: () => {
      toast.success("Quadro de horários atualizado com sucesso!");
      navigate(homeQuadro);
    },
    onError: () => {
      toast.error("Algo deu errado, tente novamente.");
    },
  });

  const getNameUnidade = async () => {
    var result = await getUnidade(localStorage.getItem("unidadeID"));
    setNomeUnidade(result.nome);
  };

  useEffect(() => {
    // cria o objeto para envio de update a cada alteração do schedule
    const data = {
      schedule: schedule,
      turmaId: id,
    };

    setRequestData(data);

    getNameUnidade();
  }, [schedule]);

  const handleUpdate = () => {
    updateRefetch(id, requestData);
  };

  const handleCancel = () => {
    navigate(homeQuadro);
  };

  const handleDelete = () => {
    deleteRefetch(id);
  };

  const handleScheduleChange = (event, rowIndex, columnName) => {
    const updatedSchedule = [...schedule];
    updatedSchedule[rowIndex][columnName] = event.target.value;
    setSchedule(updatedSchedule);
  };

  const print = () => {
    // var data = [];
    // responseData.forEach(element => {
    //   data.push({diaSemana: element.diaSemana, ...element.dadosQuadroHorario});
    // });
    localStorage.setItem(
      "quadro-horario",
      JSON.stringify({
        data: responseData,
        nomeUnidade: nomeUnidade,
        quadroHorario: state?.descricao,
      })
    );
    window.open(impressaoQuadroHorario, "_blank");
  };

  return (
    <BaseComponent>
      <Breadcrumb
        title={"Editar disciplinas do quadro"}
        itens={[
          BasePath,
          "Acadêmico",
          "Quadro de Horários",
          "Disciplinas do Quadro",
        ]}
      />
      <S.Container>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10" style={{ float: "left" }}>
              <S.Description>
                <h2>{state?.turma || "Turma"}</h2>
                <h3>{state?.descricao || "Descrição"}</h3>
              </S.Description>
            </div>
            <div
              className="col-md-2"
              style={{ float: "right", marginTop: "10px" }}
            >
              <button
                type="button"
                onClick={print}
                className="btn btn-primary"
                style={{ float: "right" }}
              >
                IMPRIMIR
              </button>
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ justifyContent: "center" }}>
          <div className="row">
            <div className="mailbox-open-content col-md-12 m-0 p-3">
              <ul
                className="nav nav-tabs"
                id="myTab"
                role="tablist"
                style={{ justifyContent: "center" }}
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="segunda-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#segunda"
                    type="button"
                    role="tab"
                    aria-controls="segunda"
                    aria-selected="true"
                  >
                    SEGUNDA-FEIRA
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="terca-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#terca"
                    type="button"
                    role="tab"
                    aria-controls="terca"
                    aria-selected="false"
                  >
                    TERÇA-FEIRA
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="quarta-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#quarta"
                    type="button"
                    role="tab"
                    aria-controls="quarta"
                    aria-selected="false"
                  >
                    QUARTA-FEIRA
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="quinta-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#quinta"
                    type="button"
                    role="tab"
                    aria-controls="quinta"
                    aria-selected="false"
                  >
                    QUINTA-FEIRA
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="sexta-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#sexta"
                    type="button"
                    role="tab"
                    aria-controls="sexta"
                    aria-selected="false"
                  >
                    SEXTA-FEIRA
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <QuadroDeHorarioAbaDia
                  data={responseData}
                  diaSemana={"Segunda"}
                />
                <QuadroDeHorarioAbaDia
                  data={responseData}
                  diaSemana={"Terca"}
                />
                <QuadroDeHorarioAbaDia
                  data={responseData}
                  diaSemana={"Quarta"}
                />
                <QuadroDeHorarioAbaDia
                  data={responseData}
                  diaSemana={"Quinta"}
                />
                <QuadroDeHorarioAbaDia
                  data={responseData}
                  diaSemana={"Sexta"}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <S.Schedule>
          <S.Header>
            <div style={{ paddingLeft: '15px' }}>Horário</div>
            <div>Segunda-feira</div>
            <div>Terça-feira</div>
            <div>Quarta-feira</div>
            <div>Quinta-feira</div>
            <div>Sexta-feira</div>
          </S.Header>
          {scheduleMock && scheduleMock.map((item, rowIndex) => (
            <S.Row key={item.id}>
              <S.CustomTextInput
                type="tel"
                inputMode="numeric"
                className="form-control"
                mask="99:99"
                value={item.time}
                disabled
                onChange={(event) => handleScheduleChange(event, rowIndex, "time")}
              />
              <S.CustomInput disabled value={item.monday} onChange={(event) => handleScheduleChange(event, rowIndex, "monday")} >
                {disciplinas.map(item => (
                  <option value={item.label}>{item.label}</option>
                ))}
              </S.CustomInput>
              <S.CustomInput disabled value={item.tuesday} onChange={(event) => handleScheduleChange(event, rowIndex, "tuesday")} >
                {disciplinas.map(item => (
                  <option value={item.label}>{item.label}</option>
                ))}
              </S.CustomInput>
              <S.CustomInput disabled value={item.wednesday} onChange={(event) => handleScheduleChange(event, rowIndex, "wednesday")} >
                {disciplinas.map(item => (
                  <option value={item.label}>{item.label}</option>
                ))}
              </S.CustomInput>
              <S.CustomInput disabled value={item.thursday} onChange={(event) => handleScheduleChange(event, rowIndex, "thursday")} >
                {disciplinas.map(item => (
                  <option value={item.label}>{item.label}</option>
                ))}
              </S.CustomInput>
              <S.CustomInput disabled value={item.friday} onChange={(event) => handleScheduleChange(event, rowIndex, "friday")} >
                {disciplinas.map(item => (
                  <option value={item.label}>{item.label}</option>
                ))}
              </S.CustomInput>
            </S.Row>
          ))}
        </S.Schedule> */}
      </S.Container>
      {/* <S.Footer> */}
      <button
        type="button"
        className="btn btn-warning"
        onClick={() => handleCancel()}
        style={{ marginTop: "10px", marginRight: "8px" }}
      >
        VOLTAR
      </button>
      {canEdit && (
        <>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handleShow}
            style={{ marginTop: "10px", marginRight: "8px" }}
          >
            EXCLUIR
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => navigate(alocarDisciplinaQuadro + id)}
            style={{ marginTop: "10px", marginRight: "8px" }}
          >
            ALOCAÇÃO
          </button>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>CONFIRMAÇÃO DE EXCLUSÃO</Modal.Title>
            </Modal.Header>
            <Modal.Body>DESEJA REALMENTE EXCLUIR?</Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => {
                  handleDelete();
                  handleClose();
                }}
              >
                EXCLUIR
              </Button>
              <Button variant="warning" onClick={handleClose}>
                VOLTAR
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
      {/* </S.Footer> */}
    </BaseComponent>
  );
};

export default QuadroDeHorarioDisciplinas;
